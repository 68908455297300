import React from "react";
import { RoundedButtonWithIcon } from "../common/Buttons";
import FadeIn from "react-fade-in/lib/FadeIn";

const Card1 = () => {
  //button icon
  const icon = (
    <img
      src="images/icons/rocket-white.png"
      alt="rocket"
      className="w-[14px] h-[14px] md:w-[22px] md:h-[24px]"
    />
  );

  return (
    <FadeIn className=" bg-white dark:bg-black dark:text-white py-20 flex justify-center px-3">
      <div className="container flex flex-col justify-center items-center md:flex-row md:space-x-8 mt-[-180px] md:mt-[0] 2xl:mt-[-180px] 4xl:mt-[-200px]">
        <div className="p-10">
          <img src="images/home/cv.png" alt="cv" />
        </div>
        <div className="flex flex-col justify-center items-start pt-10 md:pt-0 space-y-4 md:space-y-8 w-full md:max-w-xl px-6  ">
          <h3 className="text-5xl md:text-5xl  text-brand-dark">
            <span className="text-brand-purple">Improve</span> your resume on
            the fly with AI
          </h3>
          <p className="text-xl md:text-xl text-brand-grey leading-relaxed">
            With state-of-the-art AI, fix your resume    <span className="text-brand-purple italic">instantly!</span>
            <br/> Send the best version of your resume to each job
          </p>
          <RoundedButtonWithIcon text="Fix resume 🚀" />
        </div>
      </div>
    </FadeIn>
  );
};

export default Card1;
