import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      to="/login"
      className="whitespace-nowrap w-full text-white h-14 md:h-12 px-4 md:px-6 border border-[#F2F2F2] rounded-lg flex items-center justify-center text-base md:text-sm font-medium hover:bg-white/10 transition-colors"
    >
      Login
    </button>
  );
};

export default Login;
