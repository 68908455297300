import React, { useRef } from 'react';
import ToastEditor from '../components/common/Forms/ResumeForm/ToastEditor';
import CancelMembershipCard from '../components/common/Cards/CancelSubscriptionCard';

function Playground() {
  return (
    <div className="h-screen  mx-[200px] flex justify-center items-center">
      <div className="w-full h-[400px]">
        <CancelMembershipCard />
      </div>
    </div>
  );
}

export default Playground;
