import React from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Image, User } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";

const PersonalInformation = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const { user } = useAuth0();

  return (
    <FadeIn className="p-4 md:px-20 md:py-16 bg-[#D9D9D9] bg-opacity-10 flex flex-col justify-start rounded-xl items-stretch">
      <div className="text-brand-dark text-2xl joker2">Personal Information</div>
      <form onSubmit={handleSubmit} className="w-full md:block flex flex-col">
      <Avatar picture={user.picture} />
    
          <div className="flex flex-col md:flex-row md:space-x-8 w-full">
            <div className="flex flex-col space-y-1 w-full md:w-[200px]">
              <label
                htmlFor="firstName"
                className="text-[#A0A2AE]  mt-8 text-sm"
              >
                First Name
              </label>
              <input
                readOnly
                value={user.given_name}
                id="firstName"
                className="input  border text-black rounded-md px-3 py-2 focus:border-brand-cyan-dark active:border-brand-cyan-dark focus:outline-none  focus:ring-1 focus:ring-brand-cyan-dark focus:border-transparent"
              />
            </div>
            <div className="flex flex-col space-y-1 ">
              <label
                htmlFor="lastName"
                className="text-[#A0A2AE]  mt-8 text-sm"
              >
                Last Name
              </label>
              <input
                readOnly
                value={user.family_name}
                id="lastName"
                className="input md:w-[200px] border text-black rounded-md px-3 py-2 focus:border-brand-cyan-dark active:border-brand-cyan-dark focus:outline-none  focus:ring-1 focus:ring-brand-cyan-dark focus:border-transparent"
              />
            </div>
          </div>
          <button
            disabled
            type="submit"
            value="Submit"
            className="btn bg-brand-cyan-dark text-white text-sm px-8 py-3 rounded opacity-40 mt-12"
          >
            Save Changes
          </button>
        </form>
   
    </FadeIn>
  );
};

const Avatar = ({ picture }) => {
  return (
    <div className="flex flex-col md:flex-row items-center md:justify-start space-y-8 md:space-x-7 mt-8 text-sm">
      <div className="w-[120px] h-[120px] rounded-full bg-brand-cyan-dark flex justify-center items-center ">
        <User />
        <img
          src={picture}
          alt="profile"
          className="w-[120px] h-[120px] rounded-full"
        />
      </div>
      {/* <button
        onClick={changeProfilePhoto}
        className="text-brand-cyan-dark border rounded-md border-brand-cyan-dark flex items-center justify-center px-3 py-2 space-x-3 bg-white font-semibold text-sm"
      >
        <Image className="w-5 h-5" /> <span>Change Photo</span>
      </button> */}
    </div>
  );
};

export default PersonalInformation;
