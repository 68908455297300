import React from "react";

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-white text-xs md:text-base font-semibold">
      Copyright @revats {currentYear}
    </div>
  );
};

export default Copyright;
