import React from 'react';

export default function ActiveResumeTag() {
  return (
    <div className="text-[#419850] flex justify-center items-center bg-[#e7ffec] border-[#65a575] border-[0.5px] rounded-[18px] h-[18px] w-[70px] font-bold uppercase tracking-wider">
      <div>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <g clipPath="url(#clip0_1834_4475)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.26235 1.19971C7.46395 1.19971 7.6573 1.27979 7.79985 1.42235C7.9424 1.5649 8.02249 1.75824 8.02249 1.95984V5.76052H5.55205C5.40085 5.76052 5.25584 5.82058 5.14893 5.9275C5.04201 6.03441 4.98195 6.17942 4.98195 6.33062V8.80106H2.70154C2.49994 8.80106 2.3066 8.72097 2.16404 8.57842C2.02149 8.43587 1.94141 8.24252 1.94141 8.04092V1.95984C1.94141 1.75824 2.02149 1.5649 2.16404 1.42235C2.3066 1.27979 2.49994 1.19971 2.70154 1.19971H7.26235ZM8.00614 6.52065C7.9756 6.66465 7.90391 6.79669 7.79977 6.90072L6.12215 8.57834C6.01812 8.68248 5.88608 8.75418 5.74208 8.78472V6.52065H8.00614ZM4.22181 4.62032H3.84174C3.74094 4.62032 3.64427 4.66036 3.573 4.73163C3.50172 4.80291 3.46168 4.89958 3.46168 5.00038C3.46168 5.10118 3.50172 5.19785 3.573 5.26913C3.64427 5.34041 3.74094 5.38045 3.84174 5.38045H4.22181C4.32261 5.38045 4.41928 5.34041 4.49056 5.26913C4.56184 5.19785 4.60188 5.10118 4.60188 5.00038C4.60188 4.89958 4.56184 4.80291 4.49056 4.73163C4.41928 4.66036 4.32261 4.62032 4.22181 4.62032ZM6.12215 3.10004H3.84174C3.74094 3.10004 3.64427 3.14009 3.573 3.21136C3.50172 3.28264 3.46168 3.37931 3.46168 3.48011C3.46168 3.58091 3.50172 3.67758 3.573 3.74886C3.64427 3.82014 3.74094 3.86018 3.84174 3.86018H6.12215C6.22295 3.86018 6.31962 3.82014 6.3909 3.74886C6.46217 3.67758 6.50222 3.58091 6.50222 3.48011C6.50222 3.37931 6.46217 3.28264 6.3909 3.21136C6.31962 3.14009 6.22295 3.10004 6.12215 3.10004Z"
              fill="#EF7116"
            />
          </g>
          <defs>
            <clipPath id="clip0_1834_4475">
              <rect
                width="9.12162"
                height="9.12162"
                fill="white"
                transform="translate(0.419922 0.439453)"
              />
            </clipPath>
          </defs>
        </svg> */}
      </div>
      <span className="text-[10px]">Selected </span>
    </div>
  );
}
