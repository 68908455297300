import React from "react";

const CookingLoader = ({ text = "", withBox }) => {
  return (
    <>
      {withBox ? (
        <div className="z-[10000] absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="w-[90%] max-w-[600px] h-[50%] lg:h-[600px] bg-white flex flex-col justify-center items-center box-shadow">
            <div className="w-[100px] lg:w-[180px] lg:h-[180px] h-[100px] mb-4">
              <img
                src="/images/gifs/loader1.gif"
                alt="spinner"
                className="w-full h-full"
              />
            </div>
            {text && (
              <span className="text-brand-cyan text-lg md:text-3xl">
                {text}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="z-[10000] absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center">
          <div className="w-[100px] lg:w-[180px] lg:h-[180px] h-[100px] mb-4">
            <img
              src="/images/gifs/loader2.gif"
              alt="spinner"
              className="w-full h-full"
            />
          </div>
          {text && (
            <span className="text-white text-lg md:text-3xl">{text}</span>
          )}
        </div>
      )}
    </>
  );
};

export default CookingLoader;
