export const Z_INDEX = {
  base: 1,
  header: 10,
  dropdown: 20,
  modal: {
    backdrop: 9998,
    content: 9999,
  },
  toast: 10000,
  contactUs: 9997,
};
