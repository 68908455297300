import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

function StepTracker({ theme = "blue" }) {
  const [currentTheme, setCurrentTheme] = useState(2);

  // Get page from redux
  const { page } = useSelector((state) => state.project);

  // Steps
  const stepArray = [
    "Upload & Review Resume",
    "Provide Job Desc.",
    "Compare & Fix",
    "Export & Apply",
  ];

  const themes = {
    green: {
      description: { colorActive: "#27AE60", color: "#BDBDBD" },
      icon: { colorActive: "#27AE60", color: "#E0E0E0" },
    },
    blue: {
      description: { colorActive: "#2D9CDB", color: "#BDBDBD" },
      icon: { colorActive: "#38C3FF", color: "#E0E0E0" },
    },
  };

  // Set theme on mount
  useEffect(() => {
    if (theme === "green") {
      setCurrentTheme(themes.green);
    } else if (theme === "blue") {
      setCurrentTheme(themes.blue);
    }
  }, []);

  return (
    <Stepper steps={stepArray} currentStepNumber={page} theme={currentTheme} />
  );
}

const Stepper = ({ steps, currentStepNumber, theme }) => {
  const [stepperSteps, setStep] = useState([]);
  const stepsStateRef = useRef();

  useEffect(() => {
    const stepsState = steps.map((step, index) => {
      const stepObj = {};
      stepObj.description = step;
      stepObj.completed = false;
      stepObj.highlighted = index === 0 ? true : false;
      stepObj.selected = index === 0 ? true : false;
      return stepObj;
    });

    stepsStateRef.current = stepsState;
    const currentSteps = updateStep(currentStepNumber, stepsState);
    setStep(currentSteps);
  }, []);

  useEffect(() => {
    const currentSteps = updateStep(currentStepNumber, stepsStateRef.current);
    setStep(currentSteps);
  }, [currentStepNumber]);

  // keeps steps upto date
  function updateStep(stepNumber, steps) {
    const newSteps = [...steps];
    let stepCounter = 0;
    while (stepCounter < newSteps.length) {
      //current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future steps
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }
    return newSteps;
  }

  return (
    <div className="flex space-x-[60px] md:justify-between md:space-x-0 max-w-full min-w-full w-full overflow-x-scroll items-center hide-scrollbar">
      {stepperSteps.map((step, index) => (
        <Step
          theme={theme}
          key={index}
          className="relative flex items-center w-full px-6 mx-0 md:px-0"
        >
          <div
            className={`"relative flex flex-col items-center 
            } w-full h-24 md:h-28 pl-8 md:pl-0`}
          >
            <div
              className={`icon z-[2] rounded-full transition duration-500 ease-in-out h-[24px] w-[24px]  md:h-[32px] md:w-[32px] flex items-center justify-center py-3  ${
                step.selected && "selected"
              }`}
            >
              <span className="text-white font-bold w-[12px] h-[6px] md:w-[20px] md:h-[10px] md:text-xl flex justify-center items-center ">
                <img src="/images/icons/checkmark.svg" alt="checkmark" />
              </span>
            </div>
            <div
              className={` description absolute top-0  text-center mt-10 w-32 md:mt-14 text-[12px] md:text-base font-medium  ${
                step.selected && "selected"
              }`}
            >
              {" "}
              {step.description}{" "}
            </div>
            {index !== stepperSteps.length - 1 && (
              <div
                className={` absolute top-0 mt-3 md:mt-4 flex flex-auto border-t-2 left-[50%] ml-4 md:ml-0 md:left-[43%] z-0 ${
                  index === stepperSteps.length - 2
                    ? "md:w-[100%]"
                    : "md:w-[150%]"
                } ${
                  step.completed
                    ? `transition duration-500 ease-in-out border-[${theme.icon.colorActive}]`
                    : "border-[#E0E0E0]"
                } w-[150%] `}
              >
                {" "}
              </div>
            )}
          </div>
        </Step>
      ))}
    </div>
  );
};

const Step = styled.div`
  .icon {
    background-color: ${({ theme }) => theme.icon.color};
  }
  .description {
    color: ${({ theme }) => theme.description.color};
  }

  .icon.selected {
    background-color: ${({ theme }) => theme.icon.colorActive};
  }
  .description.selected {
    color: ${({ theme }) => theme.description.colorActive};
  }
`;

export default StepTracker;
