import React, { useState, useCallback } from 'react';
import TextInput from './TextInput';
import { Plus } from 'react-feather';

const NewLink = React.memo(({ handleAddLink }) => {
  const [newLinkTitle, setNewLinkTitle] = useState('');
  const [newLinkValue, setNewLinkValue] = useState('');

  const clearFields = useCallback(() => {
    setNewLinkTitle('');
    setNewLinkValue('');
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (newLinkTitle.trim() && newLinkValue.trim()) {
        handleAddLink(e, {
          title: newLinkTitle.trim(),
          value: newLinkValue.trim(),
        });
        clearFields();
      }
    },
    [newLinkTitle, newLinkValue, handleAddLink, clearFields]
  );

  return (
    <div className="flex flex-col space-y-2">
      <TextInput
        label="Link Title"
        type="text"
        value={newLinkTitle}
        handleChange={(e) => setNewLinkTitle(e.target.value)}
      />
      <TextInput
        label="Link Value"
        type="text"
        value={newLinkValue}
        handleChange={(e) => setNewLinkValue(e.target.value)}
      />
      <button
        type="button"
        onClick={handleSubmit}
        className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
      >
        <Plus className="mr-1 h-[12px] w-[12px]" />
        Add New Link
      </button>
    </div>
  );
});

export default NewLink;
