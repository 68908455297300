import React, { useState } from "react";
import { X } from "react-feather";
import { Link } from "react-router-dom";
import styled from "styled-components";

const UpgradeFailedCard = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div
      className={`${
        !showMenu && "flex items-center justify-center h-screen w-full"
      }`}
    >
      <Container className="w-full lg:w-[660px] lg:h-[682px] md:p-20">
        <div className="flex flex-col justify-center items-center w-full space-y-7  ">
          <div className="w-[176px] h-[154px] ">
            <img src="/images/gifs/cancel.gif" alt="Cancelnvelope icon" />
          </div>

          <div className="flex justify-center flex-col items-center p-4">
            <span className="text-brand-red text-[24px] md:text-[32px]  text-center mb-7">
              Upgrade Failed
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center w-full space-y-7 md:space-y-0 space-x-0 md:space-x-7">
            <Link
              to="/subscription"
              className="bg-[#2D9CDB] rounded text-[14px] px-4 py-3 w-full lg-w-[224px] text-white flex justify-center items-center"
            >
              <span>Try Again</span>
            </Link>
            <Link
              to="/user-profile"
              className="border-[#828282] border rounded text-[14px] px-4 py-3 w-full lg-w-[224px] text-[#828282] items-center flex justify-center"
            >
              Back to Dashboard
            </Link>
          </div>
        </div>
        {showMenu && (
          <button className="hidden lg:flex absolute top-0 right-0 text-black opacity-40 text-[32px] mt-6 mr-6 ">
            X
          </button>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 10px 13px 0px rgba(88, 113, 132, 0.1);
  }
`;

export default UpgradeFailedCard;
