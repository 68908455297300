import React from "react";
import { Link } from "react-router-dom";

const Title = () => {
  return (
    <div className="space-y-2 ">
      <div className="text-[#737373] text-normal lg:my-10">
        <Link to="/user-profile" className="text-[#00B3FF]">
          Home
        </Link>{" "}
        / User Profile
      </div>
      <div className="mt-10 text-4xl joker2"> Profile</div>
    </div>
  );
};

export default Title;
