import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import FadeIn from "react-fade-in/lib/FadeIn";

const _App = ({ children }) => {
  return (
    <FadeIn className="flex flex-col justify-between relative  overflow-hidden">
      <Header />
      {children}
      <Footer />
    </FadeIn>
  );
};

export default _App;
