import { loadStripe } from '@stripe/stripe-js';
import { baseUrl } from '../../store/apis/config';

let stripePromise;
const prodPK =
  'pk_live_51OJG8FGtlel2h0EnvQwLado3AOTJkWc3KIOtqik6HhQ8e6JYmfPST4mOqiWGh8At4x8KHi6SZMhKwQG4ca4fCIPn00WedohQl9';
const testPK =
  'pk_test_51OJG8FGtlel2h0EnyqnuT91eLK9IVg53w2AvOHa1iKuB1u70kDZN3crTgTvbTgLc0FDM7RCS2mvuxHESptD12ZKm00wqZcwEu3';
const PK = process.env.NODE_ENV === 'development' ? testPK : prodPK;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(PK);
  }
  return stripePromise;
};

const createCheckoutSession = async (accessToken) => {
  const priceId =
    process.env.NODE_ENV === 'development'
      ? 'price_1Pw9nAGtlel2h0EnE96q7VOX'
      : 'price_1PIZAoGtlel2h0En6ivY7kTQ';

  try {
    const response = await fetch(
      `${baseUrl}/create-checkout-session`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          price_id: priceId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to create checkout session');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Provide user feedback instead of just console logging
    console.error('Error creating checkout session:', error);
    return {
      error:
        'Failed to create checkout session. Please try again later.',
    };
  }
};

const redirectToCheckout = async (sessionID) => {
  try {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionID,
    });

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    // Display or handle error gracefully for users
    console.error('Error redirecting to checkout:', error);
  }
};

const stripe = {
  createCheckoutSession,
  redirectToCheckout,
};

export default stripe;
