import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectSlice } from '../../../store';

const UndoChangesBtn = () => {
  const dispatch = useDispatch();
  const { insights } = useSelector((state) => state.project);

  const unselectInsights = () => {
    const updatedInsights = insights.map((insight) => {
      const newInsight = { ...insight, selected: false };

      return newInsight;
    });

    dispatch(projectSlice.actions.setInsights(updatedInsights));
  };

  const undoChanges = () => {
    unselectInsights();
    dispatch(projectSlice.actions.setPage(2));
  };
  return (
    <button
      onClick={undoChanges}
      className="text-brand-blue flex justify-center space-x-3 items-center px-8 py-3 lg:bg-[#E0EDFF] rounded w-full lg:w-auto underline lg:no-underline "
    >
      <div>
        <img
          src="/images/icons/reload.svg"
          alt="undo icon"
          className="w-[12px] h-[12px] color-brand-blue"
        />
      </div>
      <span className="text-[14px]">Undo Changes</span>
    </button>
  );
};

export default UndoChangesBtn;
