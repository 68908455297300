import React from "react";

const CurrentStepCard = () => {
  return (
    <div className="hidden xl:flex justify-start bg-[#FBFBFB] bg-opacity-20 items-center border  py-4  px-7  space-x-2 rounded-xl w-full  md:space-x-5  h-[100px]">
      <div className="min-h-[32px] min-w-[32px] min-md:h-[42px] min-md:w-[42px] rounded-full bg-[#EBEBEB] flex justify-center items-center">
        <img
          src="/images/icons/settings.png"
          alt="settings icon"
          className="w-4 h-4"
        />
      </div>
      <div className=" flex flex-col space-y-1">
        {/* <span className="text-[#5F6368] text-[14px]">Current Step</span> */}
        <span className="text-[#484848] text-[20px] font-medium">
          Fix highlighted issues 
        </span>
      </div>
    </div>
  );
};

export default CurrentStepCard;
