import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { modalSlice, projectSlice } from "../../../store";
import { useNavigate } from "react-router-dom";

const DownloadSuccessfulCard = () => {
  const message = [
    "Your download was successful! Best of luck with your job search.",
    "We believe in your potential and wish you great success in finding the perfect opportunity.",
  ];

  const navigation = useNavigate();

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(modalSlice.actions.setDownloadSuccessful(false));
  };

  const gotoDashboard = () => {
    closeModal();
    dispatch(projectSlice.actions.setJob(""));
    dispatch(projectSlice.actions.setResume(null));
    navigation("/user-profile");
  };

  const compareNewJD = () => {
    closeModal();
    dispatch(projectSlice.actions.setJob(""));
    dispatch(projectSlice.actions.setResume(null));
    navigation("/user-profile");
  };

  return (
    <Container className="w-full lg:w-[660px] lg:h-[682px] p-20  bg-white  m-6 lg:m-0">
      <div className="flex flex-col justify-center items-center w-full space-y-7  ">
        <div className="w-[176px] h-[154px]">
          <img src="/images/icons/confetti.png" alt="Confetti" />
        </div>

        <div className="flex justify-center flex-col items-center p-4">
          <span className="text-[#2D9CDB] text-[24px] md:text-[32px] text-center mb-7">
            Download Successful!
          </span>
          {message.map((msg, i) => (
            <span
              className="text-[14px] text-[#312E2E] mb-2 flex flex-col text-center"
              key={i}
            >
              {msg}
            </span>
          ))}
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center w-full space-y-7 md:space-y-0 space-x-0 md:space-x-7">
          <button
            onClick={compareNewJD}
            className="bg-[#2D9CDB] rounded text-[14px] px-4 py-3 w-full lg-w-[224px] text-white"
          >
            Compare new JD
          </button>
          <button
            onClick={gotoDashboard}
            className="border-[#828282] border rounded text-[14px] px-4 py-3 w-full lg-w-[224px] text-[#828282]"
          >
            Take me to Dashboard
          </button>
        </div>
      </div>
      <button
        onClick={closeModal}
        className=" lg:flex absolute top-0 right-0 text-black opacity-40 text-[32px] mt-6 mr-6 "
      >
        X
      </button>
    </Container>
  );
};

export default DownloadSuccessfulCard;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 13px 0px rgba(88, 113, 132, 0.1);
`;
