import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    fromHomePage: false,
    resumes: [],
    resume: null, // resume file(pdf)
    defaultResume: null,
    resumeTitle: null,
    activeResume: null,
    resumeId: null,
    resumeObj: null, // returned from pdf parser
    enhancedResume: null,
    job: null,
    page: 1, // 0: resume review, 1: job description, 2: compare, 3: result page
    score: null,
    insights: [],
    selectedInsights: [],
    continueWithoutFixing: false,
  },
  reducers: {
    setResumes: (state, { payload }) => {
      state.resumes = payload;
    },
    setResume: (state, { payload }) => {
      state.resume = payload;
      state.page = 1;
    },
    setResumeObj: (state, { payload }) => {
      state.resumeObj = payload;
    },
    setDefaultResume: (state, { payload }) => {
      state.defaultResume = payload;
    },
    setActiveResume: (state, { payload }) => {
      state.activeResume = payload;
    },
    setResumeTitle: (state, { payload }) => {
      state.resumeTitle = payload;
    },
    setEnhancedResume: (state, { payload }) => {
      state.enhancedResume = payload;
    },
    setJob: (state, { payload }) => {
      state.job = payload;
    },
    setScore: (state, { payload }) => {
      state.score = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setResumeId: (state, { payload }) => {
      state.resumeId = payload;
    },
    setInsights: (state, { payload }) => {
      state.insights = payload;

      const selected = state.insights.filter(
        (insight) => insight.selected
      );
      state.selectedInsights = selected.map(
        (insight) => insight.insight
      );
    },
    removeResume: (state, { payload }) => {
      state.resumes = state.resumes.filter(
        (resume) => resume.id !== payload
      );
    },
    setContinueWithoutFixing: (state, { payload }) => {
      state.continueWithoutFixing = payload;
    },
    removeResume: (state, { payload }) => {
      state.resumes = state.resumes.filter(
        (resume) => resume.id !== payload
      );
    },
    setFromHomePage: (state, { payload }) => {
      state.fromHomePage = payload;
    },
  },
});

export default projectSlice;
