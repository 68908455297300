import React, { useEffect, useRef, useState } from 'react';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

export default function ToastEditor({
  initialValue = '',
  height = '500px',
  previewStyle = 'tab',
  onChange,
  hideModeSwitch = true,
  toolbarItems,
  usageStatistics = false,
  onEditorInstance,
}) {
  const editorRef = useRef(null);
  const editorInstanceRef = useRef(null);
  const [currentHtml, setCurrentHtml] = useState(initialValue);

  useEffect(() => {
    editorInstanceRef.current = new Editor({
      el: editorRef.current,
      initialValue,
      height,
      initialEditType: 'wysiwyg',
      previewStyle,
      hideModeSwitch,
      autofocus: false,
      usageStatistics,
      toolbarItems: toolbarItems || [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task'],
        ['indent', 'outdent'],
        ['table', 'link'],
      ],
    });

    if (onChange) {
      editorInstanceRef.current.on('change', () => {
        const newHtml = editorInstanceRef.current.getHTML();

        if (newHtml !== currentHtml) {
          setCurrentHtml(newHtml);
          onChange(newHtml);
        }
      });
    }

    if (onEditorInstance) {
      onEditorInstance(editorInstanceRef.current);
    }

    return () => {
      editorInstanceRef.current.destroy();
    };
  }, []);

  return <div className="border rounded-xl w-full" ref={editorRef} />;
}
