import React from 'react';
import { Link } from 'react-router-dom';

function ContactUs() {
  return (
    <Link
      to="mailto:help@revats.ai"
      className="hidden lg:flex justify-center items-center space-x-2 p-4 rounded-[44px] md:h-11  bg-white text-[#858585]"
      style={{ boxShadow: '0px 4px 15px 0px #00000024' }}
    >
      <div className="w-4 h-4 ">
        <img
          className="w-full h-full"
          src="/images/icons/email-send.png"
          alt="email icon"
        />
      </div>
    </Link>
  );
}

export default ContactUs;
