import React from 'react';
import { Title } from './index';
import PersonalInformation from './PersonalInformation';
import styled from 'styled-components';
import Billing from './Billing';
import { scrollToElement } from '../../utils/scrollToElement';

const Container = styled.div`
  .input {
    border-radius: 8px;
    border: 1px solid var(--Grey-Grey-10, #d3d5db);
    background: var(--Grey-White, #fff);
  }
`;

const Account = ({ portalURL }) => {
  return (
    <Container className="2xl:px-[300px] my-6 flex flex-col">
      <Title />
      <div className="flex lg:space-x-18 mt-16 lg:space-x-16">
        <div className="hidden lg:block min-w-max">
          <Tabs />
        </div>
        <div className=" w-full flex flex-col space-y-8 ">
          <PersonalInformation />
          <Billing portalURL={portalURL} />
        </div>
      </div>
    </Container>
  );
};

export default Account;

const Tab = ({ tab, selectedTab, setSelectedTab }) => {
  const handleClick = () => {
    selectTab();
    scrollToElement(tab.id);
  };

  const selectTab = () => {
    selectedTab !== tab.id && setSelectedTab(tab.id);
  };

  return (
    <button
      onClick={selectTab}
      className={` text-normal hover:text-[#00B3FF] ${
        tab.id === selectedTab ? 'text-[#00B3FF]' : 'text-[#9B9B9B]'
      }`}
    >
      {tab?.name}
    </button>
  );
};

const Tabs = () => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const tabs = [
    {
      id: 1,
      name: 'Personal Information',
    },
    {
      id: 2,
      name: 'Billing',
    },
  ];

  return (
    <div className="flex flex-col space-y-9 items-start mt-6 text-[20px]">
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          tab={tab}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      ))}
    </div>
  );
};
