import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ComparePage from './ComparePage';
import ResultPage from './ResultPage';
import ResumePage from './ResumePage';
import ContactUs from '../../components/common/ContactUs';
import DashboardHeader from '../../components/common/DashboardHeader';

const CREATE_OR_UPDATE_RESUME = 1;
const COMPARE_PAGE = 2;
const RESULTS_PAGE = 3;

export default function ProjectView() {
  const { page, resumeObj } = useSelector((state) => state.project);

  const navigate = useNavigate();

  useEffect(() => {
    if (resumeObj === null) {
      navigate('/user-profile');
    }
  }, [resumeObj, navigate]);

  const pages = {
    [CREATE_OR_UPDATE_RESUME]: <ResumePage />,

    [COMPARE_PAGE]: <ComparePage />,

    [RESULTS_PAGE]: <ResultPage />,
  };

  return (
    <div className="md:h-screen md:max-h-screen  md:overflow-hidden bg-white dark:bg-black px-3 md:px-6 m-0 space-y-10">
      <DashboardHeader />
      {pages[page]}
      <div className="fixed right-0 bottom-5 m-8 ">
        <ContactUs />
      </div>
    </div>
  );
}
