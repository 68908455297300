import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';
import {
  useDeleteResumeMutation,
  useSetDefaultResumeMutation,
  useUpdateResumeMutation,
} from '../../../store';
import { projectSlice } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActiveResumeTag from './ActiveResumeTag';
import { toast } from 'react-toastify';
import { SpinnerLoader } from '../Loader';
import { FaRegCircle } from 'react-icons/fa';
import {
  ConfirmDeleteDialogue,
  RenameResumeDialogue,
} from '../Dialogue';
import { createPortal } from 'react-dom';
import { Z_INDEX } from '../../../styles/zIndex';
import Portal from '../Portal';
import ResumeTemplates from './ResumeTemplates';
import ModalWrapper from '../StyledComponents/ModalWrapper.styled';
import { motion, AnimatePresence } from 'framer-motion';

const ResumeCardSmall = ({ title, lastUpdated, content, id }) => {
  const { activeResume, resumes, defaultResume } = useSelector(
    (state) => state.project
  );

  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [shouldFlipMenu, setShouldFlipMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const [shouldShowAbove, setShouldShowAbove] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [resume] = useState(content);

  const menuTriggerRef = useRef(null);
  const menuRef = useRef(null);

  const [deleteResume, { isLoading: deleteResumeIsLoading }] =
    useDeleteResumeMutation();
  const [setDefaultResume, { isLoading: setDefaultResumeIsLoading }] =
    useSetDefaultResumeMutation();
  const [
    updateResume,
    {
      data: editedResume,
      isLoading: renameIsLoading,
      error: renameError,
    },
  ] = useUpdateResumeMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showMenu]);

  useEffect(() => {}, [activeResume]);

  useEffect(() => {
    if (
      showMenu &&
      menuTriggerRef.current &&
      window.innerWidth > 640
    ) {
      const triggerRect =
        menuTriggerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - triggerRect.bottom;
      const menuHeight = 100; // Approximate menu height

      setShouldShowAbove(spaceBelow < menuHeight);
      setMenuPosition({
        top: triggerRect.bottom + 8,
        right: window.innerWidth - triggerRect.right,
      });
    }
  }, [showMenu]);

  const editResume = () => {
    let resumeObj = resume;
    if (typeof resume !== 'object') {
      resumeObj = JSON.parse(resume);
    }

    dispatch(
      projectSlice.actions.setResumeObj({
        ...resumeObj,
        id,
        title,
      })
    );

    dispatch(projectSlice.actions.setPage(1));

    navigate('/project-view');
  };

  const exportCV = async () => {
    setLoading(true);
    setShowExportModal(true);
    setLoading(false);
    setShowMenu(false);
  };

  const handleDeleteResume = async () => {
    const resumeId = id;
    try {
      const res = await deleteResume(resumeId);

      if (res.error) {
        toast.error('Failed to delete resume');
        return;
      }

      if (activeResume?.id === resumeId) {
        dispatch(
          projectSlice.actions.setActiveResume(resumes[0] || null)
        );
      }
      if (defaultResume?.id === resumeId) {
        dispatch(
          projectSlice.actions.setDefaultResume(resumes[0] || null)
        );
      }
      dispatch(projectSlice.actions.removeResume(resumeId));
      toast.success('Resume deleted successfully');
    } catch (error) {
      toast.error('Failed to delete resume');
      console.error('Error deleting resume:', error);
    } finally {
      setShowDeleteDialog(false);
    }
  };

  const handleRenameResume = async ({ newTitle }) => {
    if (newTitle === title) {
      setShowRenameModal(false);
      return;
    }

    if (newTitle.length < 3) {
      toast.error('Title must be at least 3 characters long');
      return;
    }

    setLoading(true);
    setShowRenameModal(false);

    let resumeObj = resume;
    if (typeof resume === 'object') {
      resumeObj = JSON.stringify(resume);
    }

    try {
      await updateResume({
        id: id,
        resume: { content: resumeObj, title: newTitle },
      });

      const updatedResumes = resumes.map((resume) =>
        resume.id === id ? { ...resume, title: newTitle } : resume
      );

      dispatch(projectSlice.actions.setResumes(updatedResumes));
      toast.success('Resume renamed successfully');
    } catch (error) {
      toast.error('Failed to rename resume');
      console.error('Error renaming resume:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetActiveResume = () => {
    dispatch(
      projectSlice.actions.setActiveResume({
        content,
        id,
        title,
      })
    );
  };

  const closeExportModal = () => {
    setShowExportModal(false);
  };

  const menuItems = [
    {
      label: 'Rename',
      onClick: (e) => {
        e.stopPropagation();
        setShowRenameModal(true);
        setShowMenu(false);
      },
    },
    {
      label: 'Edit Resume',
      icon: '/images/icons/edit-icon.png',
      onClick: (e) => {
        e.stopPropagation();
        editResume();
        setShowMenu(false);
      },
    },
    {
      label: 'Export as PDF',
      icon: '/images/icons/pdf.png',
      onClick: (e) => {
        e.stopPropagation();
        exportCV();
      },
      className: 'text-brand-blu',
    },
    {
      label: 'Delete',
      icon: '/images/icons/delete-icon.png',
      onClick: (e) => {
        e.stopPropagation();
        setShowDeleteDialog(true);
        setShowMenu(false);
      },
      className: 'text-danger',
    },
  ];

  if (renameError) {
    toast.error('Failed to rename resume');
    console.error('Error renaming resume:', renameError);
  }

  return (
    <CardWrapper
      onClick={handleSetActiveResume}
      whileHover={{ scale: 1.01 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {(loading ||
        deleteResumeIsLoading ||
        setDefaultResumeIsLoading) && (
        <Portal>
          <SpinnerLoader />
        </Portal>
      )}
      <CardContent>
        <SelectionIndicator>
          {activeResume?.id === id ? (
            <img
              src="/images/icons/check-filled.png"
              alt="Selected"
              className="w-6 h-6"
            />
          ) : (
            <FaRegCircle className="w-5 h-5 text-brand-cyan" />
          )}
        </SelectionIndicator>

        <ResumePreview>
          <IconContainer>
            <img
              src="/images/dashboard/application.png"
              alt="Resume"
              className="w-12 h-9"
            />
          </IconContainer>

          <ResumeInfo>
            {activeResume?.id === id && <ActiveResumeTag />}
            <ResumeTitle>{title}</ResumeTitle>
            <LastEdited>{lastUpdated}</LastEdited>
          </ResumeInfo>
        </ResumePreview>

        <MenuContainer>
          <MenuTrigger
            ref={menuTriggerRef}
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
          >
            <MoreVertical className="w-6 h-6" color="#828282" />
          </MenuTrigger>

          {showMenu && (
            <Portal>
              <MenuBackdrop onClick={() => setShowMenu(false)} />
              <AnimatePresence>
                <MenuDropdown
                  ref={menuRef}
                  menuPosition={menuPosition}
                  shouldShowAbove={shouldShowAbove}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  {menuItems.map((item, index) => (
                    <MenuItem
                      key={item.label}
                      onClick={item.onClick}
                      className={item.className}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      whileHover={{ x: 5 }}
                    >
                      {item.icon ? (
                        <img
                          src={item.icon}
                          alt={item.label}
                          className="w-4 h-4"
                        />
                      ) : (
                        <div className="w-4 h-4" />
                      )}

                      <span>{item.label}</span>
                    </MenuItem>
                  ))}
                </MenuDropdown>
              </AnimatePresence>
            </Portal>
          )}
        </MenuContainer>
      </CardContent>

      {showDeleteDialog && (
        <Portal>
          <ModalWrapper>
            <ConfirmDeleteDialogue
              title="Remove resume?"
              description="Are you sure you want to delete this resume? You will lose all data related to this resume."
              cancel={() => setShowDeleteDialog(false)}
              handleDelete={handleDeleteResume}
            />
          </ModalWrapper>
        </Portal>
      )}

      {showRenameModal && (
        <Portal>
          <ModalWrapper>
            <RenameResumeDialogue
              cancel={() => setShowRenameModal(false)}
              handleRenameResume={handleRenameResume}
            />
          </ModalWrapper>
        </Portal>
      )}

      {showExportModal && (
        <Portal>
          <ModalWrapper>
            <ResumeTemplates
              resumeId={id}
              closeModal={closeExportModal}
              modalIsOpen={showExportModal}
            />
          </ModalWrapper>
        </Portal>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled(motion.button)`
  width: 100%;
  text-align: left;
  position: relative;
`;

const CardContent = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #b3d7e6;
  border-radius: 1rem;
  background: white;
  transition: box-shadow 0.3s ease;
  gap: 1rem;

  &:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  }

  @media (min-width: 641px) {
    padding: 1rem;
    gap: 1.5rem;
  }
`;

const SelectionIndicator = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1280px) {
    width: 24px;
    height: 24px;
  }
`;

const ResumePreview = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-grow: 1;
  min-width: 0;

  @media (min-width: 1280px) {
    gap: 1.25rem;
  }
`;

const IconContainer = styled.div`
  width: 48px;
  height: 36px;
  background-color: #cee8f3;
  border-radius: 0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;

  @media (min-width: 641px) {
    width: 64px;
    height: 48px;
  }

  img {
    width: auto;
    height: 75%;
  }
`;

const ResumeInfo = styled.div`
  min-width: 0; // Enables text truncation
`;

const ResumeTitle = styled.div`
  color: var(--brand-cyan-dark);
  font-weight: 600;
  font-size: 0.875rem;
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 641px) {
    font-size: 1rem;
  }
`;

const LastEdited = styled.div`
  font-size: 0.75rem;
  color: #828282;

  span {
    opacity: 0.7;
  }

  @media (min-width: 1280px) {
    font-size: 0.875rem;
  }
`;

const MenuContainer = styled.div`
  position: relative;
  margin-left: auto;
  flex-shrink: 0;
`;

const MenuTrigger = styled.button`
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 641px) {
    padding: 0.5rem;
  }

  svg {
    width: 20px;
    height: 20px;

    @media (min-width: 641px) {
      width: 24px;
      height: 24px;
    }
  }
`;

const MenuDropdown = styled(motion.div)`
  position: absolute;
  background: white;
  border-radius: 0.625rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 220px;
  z-index: 9999;

  @media (min-width: 640px) {
    position: fixed;
    top: auto;
    bottom: ${(props) =>
      props.shouldShowAbove ? 'calc(100% + 8px)' : 'auto'};
    right: ${(props) => props.menuPosition?.right}px;
    top: ${(props) =>
      !props.shouldShowAbove ? props.menuPosition?.top : 'auto'}px;
  }

  @media (max-width: 640px) {
    position: fixed;
    bottom: 24px;
    left: 16px;
    right: 16px;
    transform: none;
    width: auto;
    max-width: none;
  }
`;

const MenuItem = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }

  &.text-danger {
    color: #e95050;
  }
`;

const MenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.modal.backdrop};
  display: none;

  @media (max-width: 1280px) {
    display: block;
  }
`;

export default ResumeCardSmall;
