import React from "react";
import socialLinks from "../../data/socialLinks";

const Socials = () => {
  return (
    <div className="flex space-x-6 items-center">
      {socialLinks.map((socialLink, i) => (
        <a key={i} href={socialLink.link} target="_blank" rel="noreferrer">
          <img
            src={socialLink.icon}
            alt={socialLink.name}
            className="w-[20px] h-[22px] md:w-full md:h-full"
          />
        </a>
      ))}
    </div>
  );
};

export default Socials;
