const testimonials = [
  {
    name: "Sarah Anderson",
    role: " Senior Software Engineer",
    image: "images/testimonials/sarah.png",
    quote: `Zero stress when customizing my CVs for different application! The AI's personalized suggestions was really the game changer for me. I secured multiple job interviews and ultimately landed a fantastic remote role in June.`,
  },
  {
    name: "Michael Chen",
    role: "Digital Marketing Intern",
    image: "images/testimonials/michael.png",
    quote: `Saved me valuable time and effort during my internship search. As a recent graduate, crafting a standout resume was daunting. But their AI thingy works just like grammarly but for your resume! Give it a shot!`,
  },
  {
    name: "Vincent Rodriguez",
    role: "User Experience Designer",
    image: "images/testimonials/vincent.png",
    quote: `It's my first time ever trying these AI tools. I am impressed. Revats took away the usual manual labor of resume crafting. The results were mindblowing. The app significantly reduced my stress levels by providing tailored suggestions effortlessly.`,
  },
];

export default testimonials;
