import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './routing/ProtectedRoute';
import ProjectView from './pages/Project';
import UploadResume from './pages/UploadResume';
import MyAccount from './pages/MyAccount';
import MyResumes from './pages/MyResumes';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Subscription from './pages/Subscription';
import FeedbackForm from './components/common/Cards/FeedbackForm';
import Playground from './pages/Playground';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/feedback" element={<FeedbackForm />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/user-profile" element={<Dashboard />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/playground" element={<Playground />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/account" element={<MyAccount />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/subscription" element={<Subscription />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/resumes" element={<MyResumes />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/project-view" element={<ProjectView />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/upload-resume" element={<UploadResume />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/success" element={<Success />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/cancel" element={<Cancel />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} />
    </Router>
  );
}
