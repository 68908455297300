import React from "react";
import _APP from "./_App";
import Hero from "./Hero";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Testimonials from "./Testimonials";

const Home = ({ isAuthenticated }) => {
  return (
    <>
      <_APP>
        <Hero isAuthenticated={isAuthenticated} />
        <Card1 />
        <Card2 />
        <Testimonials />
      </_APP>
    </>
  );
};

export default Home;
