import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoginPage from "../pages/LoginPage";
import { useLocation } from "react-router-dom";
import { SpinnerLoader } from "../components/common/Loader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authSlice } from "../store";
import { useResumesMutation } from "../store";

const ProtectedRoute = () => {
  // const [isSubscribed, setIsSubscribed] = useState(false);
  const location = useLocation();
  const { isAuthenticated, user, isLoading, error, getAccessTokenSilently } =
    useAuth0();

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(authSlice.actions.setCredentials(user));
    }
  }, [user, dispatch]);

  if (isLoading) return <SpinnerLoader />;

  if (error) return <div>Oops... {error.message}</div>;

  return (
    <>{isAuthenticated ? <Outlet /> : <LoginPage location={location} />}</>
  );
};

export default ProtectedRoute;
