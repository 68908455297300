import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoader } from "../components/common/Loader";


const RegisterPage = () => {
  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    // redirect authenticated user to profile screen
    if (isAuthenticated) navigate("/user-profile");
    // redirect user to login page if registration was successful
    if (!isAuthenticated) navigate("/login");
  }, [navigate, isAuthenticated]);

  return (
    <SpinnerLoader />
    // <div className="flex flex-col justify-between min-h-screen md:block  bg:white dark:bg-brand-dark">
    //   <div className="md:hidden px-8 mt-10">
    //     <div className="dark:hidden block">
    //       <Logo theme="dark" />
    //     </div>
    //     <div className="hidden dark:block">
    //       <Logo theme="light" />
    //     </div>
    //   </div>
    //   <Register loading={loading} error={error} />
    //   <div className="md:hidden">
    //     <Footer />
    //   </div>
    // </div>
  );
};

export default RegisterPage;
