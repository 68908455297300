import React from 'react';
import styled from 'styled-components';

const HorizontalTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;
  padding: 10px 0;
`;

const TabButton = styled.button`
  min-width: fit-content;
  padding: 10px 20px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${(props) => (props.selected ? 'brand-cyan-dark' : 'black')};
  opacity: ${(props) => (props.selected ? '1' : '0.3')};
  border-bottom: ${(props) =>
    props.selected ? '2px solid brand-cyan-dark' : 'none'};
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const HorizontalResumeTab = ({
  tabs,
  selectedTab,
  handleTabClick,
}) => {
  return (
    <HorizontalTabContainer>
      {tabs?.map((tab, i) => (
        <TabButton
          key={i}
          selected={selectedTab === tab.ref}
          onClick={() => handleTabClick(tab.ref)}
        >
          {tab.name}
        </TabButton>
      ))}
    </HorizontalTabContainer>
  );
};

export default HorizontalResumeTab;
