import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ExportCvBtn, UndoChangesBtn } from '../../Buttons';
import { numToRomanNumeralsPair } from '../../../../constants';
import PersonalInformation from './PersonalInformation';
import Skills from './Skills';
import Education from './Education';
import WorkExperience from './WorkExperience';
import Certificates from './Certificates';
import Links from './Links';
import NewLink from './NewLink';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export const DeleteButton = ({ onClick, children, className }) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`inline-flex items-center justify-center p-1 text-red-500 hover:text-red-700 opacity-10 hover:opacity-100 transition-opacity duration-200 focus:outline-none ${className || ''}`}
    >
      {children}
    </button>
  );
};

const ResumeForm = ({
  selectedTab,
  data,
  setData,
  shouldScroll,
  setShouldScroll,
}) => {
  const navigate = useNavigate();

  // Move all hooks to the top level
  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();

  const { page } = useSelector((state) => state.project);

  const refs = useMemo(
    () => ({
      section1: section1Ref,
      section2: section2Ref,
      section3: section3Ref,
      section4: section4Ref,
      section5: section5Ref,
    }),
    []
  );

  // Check for data existence
  useEffect(() => {
    if (!data) {
      navigate('/dashboard');
    }
  }, [data, navigate]);

  // Scroll handling
  useLayoutEffect(() => {
    if (!data || !shouldScroll) return;

    if (selectedTab) {
      const ref = refs[selectedTab];
      if (ref?.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        // Reset the scroll flag after scrolling
        setShouldScroll(false);
      }
    }
  }, [selectedTab, refs, data, shouldScroll, setShouldScroll]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setData((prevData) => {
        const prevDataObj = prevData || {};
        const updatedData = { ...prevDataObj, [name]: value };
        if (name === 'first-name' || name === 'last-name') {
          updatedData.name =
            `${name === 'first-name' ? value : prevDataObj['first-name'] || ''} ${name === 'last-name' ? value : prevDataObj['last-name'] || ''}`.trim();
        }
        return updatedData;
      });
    },
    [setData]
  );

  const handleDateRangeChange = useCallback(
    (e, index, section) => {
      const { name, value } = e.target;
      setData((prevData) => {
        const updatedSection = [...(prevData[section] || [])];
        updatedSection[index] = {
          ...updatedSection[index],
          [name]: value,
        };
        return { ...prevData, [section]: updatedSection };
      });
    },
    [setData]
  );

  const handleAddLink = useCallback(
    (e, newLink) => {
      e.preventDefault();
      setData((prevData) => ({
        ...prevData,
        links: [...(prevData?.links || []), newLink],
      }));
    },
    [setData]
  );

  // Cleanup effect
  useEffect(() => {
    return () => {
      const exportFrame = document.getElementById('export-frame');
      if (exportFrame && document.body.contains(exportFrame)) {
        exportFrame.parentNode.removeChild(exportFrame);
      }
    };
  }, []);

  // Name splitting effect
  useEffect(() => {
    if (!data?.name) return;

    function splitFullName(fullName) {
      const names = fullName.trim().split(/\s+/);
      const lastName = names.length > 1 ? names.pop() : '';
      const firstName = names.join(' ');
      return { firstName, lastName };
    }

    const { firstName, lastName } = splitFullName(data.name);

    if (
      data &&
      (data['first-name'] !== firstName ||
        data['last-name'] !== lastName)
    ) {
      setData((prevData) => ({
        ...prevData,
        'first-name': firstName,
        'last-name': lastName,
      }));
    }
  }, [
    data?.name,
    data?.['first-name'],
    data?.['last-name'],
    setData,
  ]);

  // Early return after all hooks
  if (!data) return null;

  const Congratulations = (
    <div className="flex flex-col justify-center items-center w-full ">
      <div className="mb-2 flex justify-center">
        <img
          src="/images/gifs/success.gif"
          alt="success"
          className="w-[100%] h-[100%]"
        />
      </div>
      <div className="flex flex-col justify-center items-center text-center text-black font-normals mb-10">
        <span className="text-brand-green text-xl lg:text-3xl mb-2 joker2">
          Awesome!
        </span>
        <span className="mb-2">
          Your CV has been updated to match the job description!
        </span>
        <span>
          Now, you can export your CV using any of our beautiful
          templates.
          <br />
          We are rooting for you!{' '}
          <span className="font-bold">Good luck!</span>
        </span>
      </div>
      <div className="flex justify-center space-x-6 w-fit ">
        <div className="export-btn-container">
          <ExportCvBtn data={data} />
        </div>
        <div>
          <UndoChangesBtn />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full h-full">
      <div className="mb-8 mx-3 md:mx-0">
        {page === 2 && (
          <div className="flex items-center space-x-3"></div>
        )}
        {page === 3 && Congratulations}
      </div>
      <form className="bg-white lg:bg-[#D9D9D9] lg:bg-opacity-10 px-3 md:px-10 py-6 flex flex-col w-full space-y-[58px] pb-32">
        <div ref={section1Ref}>
          <PersonalInformation
            handleChange={handleChange}
            data={data}
            setData={setData}
          />
        </div>
        <div ref={section2Ref}>
          <Skills setData={setData} data={data} />
        </div>
        <div ref={section3Ref} className="w-full">
          <Education
            setData={setData}
            handleDateRangeChange={handleDateRangeChange}
            data={data}
          />
        </div>
        <div ref={section4Ref} className="w-full">
          <WorkExperience
            data={data}
            setData={setData}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
        <div className="w-full">
          <Certificates data={data} setData={setData} />
        </div>
        <div ref={section5Ref} />
        {data?.links ? (
          <Links
            handleChange={handleChange}
            links={data.links}
            handleAddLink={handleAddLink}
            setData={setData}
          />
        ) : (
          <NewLink handleAddLink={handleAddLink} />
        )}
      </form>
    </div>
  );
};

export default ResumeForm;
