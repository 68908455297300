import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { CreditCard } from 'react-feather';
import { Link } from 'react-router-dom';

const Billing = ({ portalURL }) => {
  return (
    <FadeIn className="p-4 md:px-20 md:py-16 bg-[#D9D9D9] bg-opacity-10 flex flex-col justify-start rounded-xl items-stretch">
      <div className="text-brand-dark text-2xl flex justify-between ">
        <span>Billing</span>{' '}
        <Link
          to={
            typeof portalURL === 'string'
              ? portalURL
              : '/subscription'
          }
          className="text-brand-cyan-dark text-xs md:text-base"
        >
          Manage Subscription
        </Link>
      </div>

      {/* <div className="mt-8 flex flex-col space-y-10 md:space-y-16 bg-white p-6 md:p-14 rounded-xl">
        <div className="flex justify-between w-full lg:justify-start lg:space-x-64">
          <div className="flex flex-col items-start justify-center space-y-3 w-40 md:w-56">
            <div className="text-[#373434] font-bold">Current Plan</div>
            <div className="text-[#373434] font-normal">Premium Plan</div>
          </div>
          <div className="flex flex-col items-start justify-center space-y-3 w-40 md:w-56">
            <div className="text-[#373434] font-bold">
              Next Payment Estimate
            </div>
            <div className="text-[#373434] font-normal">$25/ month</div>
          </div>
        </div>

        <div className="flex justify-between w-full lg:justify-start lg:space-x-64">
          <div className="flex flex-col items-start justify-center space-y-3 w-40 md:w-56">
            <div className="text-[#373434] font-bold">
              Next Payment Estimate
            </div>
            <div className="text-[#373434] font-normal">20 USD</div>
          </div>
          <div className="flex flex-col items-start justify-center space-y-3 w-40 md:w-56">
            <div className="text-[#373434] font-bold">Next Payment Date</div>
            <div className="text-[#373434] font-normal">Premium Plan</div>
          </div>
        </div>

        <div className="">
          <div className="text-[#373434] font-bold">Card on File</div>
          <div className="flex flex-col md:flex-row md:justify  space-y-3 md:space-x-10 md:space-y-0 w-full">
            <div className="text-[#373434] font-normal flex w-full md:w-56">
              <CreditCard className="inline-block mr-2" />

              <span>5312 **** **** **** 5242</span>
            </div>
            <button className="text-brand-cyan-dark text-xs md:text-sm font-bold w-fit md:w-56">
              Update Payment Details
            </button>
          </div>
        </div>
      </div> */}
    </FadeIn>
  );
};

export default Billing;
