import React from "react";

const Author = ({ name, image, role }) => {
  return (
    <div className="flex justify-center items-center space-x-2">
      <div className="flex items-center justify-center ">
        <img src={image} alt="author" />
      </div>
      <div className="flex flex-col items-start justify-center ">
        <span className="mb-[-1px] text-base md-text-sm">{name}</span>
        <span className="opacity-70 mt-[-1px] text-base md-text-lg">{role}</span>
      </div>
      <div className="flex items-center justify-center">
        <img src="images/icons/circle-check.png" alt="author" />
      </div>
    </div>
  );
};

export default Author;
