import React from 'react';

const TextInput = ({
  type,
  label,
  name,
  value,
  handleChange,
  className,
  placeholder,
}) => {
  return (
    <label
      htmlFor={name}
      className={`text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2 w-full ${
        label && 'mt-3'
      } ${type === 'textArea' && 'mt-4'}`}
    >
      {label && <span>{label}</span>}
      {type === 'textArea' ? (
        <textarea
          value={value}
          id={name}
          name={name}
          className={`w-full rounded-[8px] border-[1px] h-auto min-h-full border-[#D3D5DB] py-3 px-4 focus:outline-none focus:ring-1 focus:ring-brand-cyan-dark focus:border-transparent ${className}`}
          onChange={handleChange}
          wrap="soft"
          placeholder={placeholder}
        ></textarea>
      ) : (
        <input
          value={value}
          id={name}
          name={name}
          type={type}
          className={`w-full rounded-[8px] border-[1px] border-[#D3D5DB] py-3 px-4 focus:outline-none focus:ring-1 focus:ring-brand-cyan-dark focus:border-transparent ${className}`}
          onChange={handleChange}
          placeholder={placeholder}
        ></input>
      )}
    </label>
  );
};

export default TextInput;
