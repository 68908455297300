import React from "react";
import DashboardHeader from "../components/common/DashboardHeader";
import { UploadResumeCard } from "../components/common/Cards";
import Footer from "../components/common/Footer";
import ContactUs from "../components/common/ContactUs";

const UploadResume = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex justify-center items-center py-10">
        <UploadResumeCard />
      </div>
      <Footer />
      <div className="fixed right-0 bottom-0 m-8 ">
        <ContactUs />
      </div>
    </div>
  );
};

export default UploadResume;
