import React from 'react';
import Logo from './Logo';
import Avatar from './Avatar';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  @media (min-width: 768px) {
    box-shadow: 0px 8px 40px -12px rgba(135, 135, 135, 0.15);
  }
`;

const DashboardHeader = () => {
  return (
    <FadeIn className="w-full">
      <Container className="flex justify-between items-center  py-4  w-full">
        <div className="dark:hidden block">
          <Logo theme="dark" url="/user-profile" />
        </div>
        <div className="hidden dark:block">
          <Logo theme="light" url="/user-profile" />
        </div>
        <div className="flex justify-center items-center space-x-12">
          <div className="hidden lg:block">
            {/* <Link
              to="/resumes"
              className="text-sm font-xs text-black opacity-60 hover:text-[#00B3FF] hover:opacity-100"
            >
              History
            </Link> */}
          </div>
          <div>
            <Avatar />
          </div>
        </div>
      </Container>
    </FadeIn>
  );
};

export default DashboardHeader;
