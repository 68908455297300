import React from "react";
import ReactQuill from "react-quill";

const QuillEditor = ({
  quillRef,
  setTextAreaFocus,
  setJobDescription,
  jobDescription,
}) => {
  return (
    <ReactQuill
      ref={quillRef}
      onChange={setJobDescription}
      value={jobDescription}
      onFocus={() => {
        setTextAreaFocus(true);
      }}
      className="bg-transparent  outline-none p-4  text-white absolute inset-0 border-0  hide-scrollbar flex flex-col-reverse"
      style={{ border: "none" }}
    />
  );
};

export default QuillEditor;
