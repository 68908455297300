function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Byte";

  const k = 1024; // or 1000 depending on your definition of a kilobyte
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
  );
}

export default formatBytes;
