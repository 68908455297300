const templates = [
  { img: '/images/resumeTemplates/azurill.jpg', name: 'azurill' },
  { img: '/images/resumeTemplates/bronzor.jpg', name: 'bronzor' },
  { img: '/images/resumeTemplates/chikorita.jpg', name: 'chikorita' },
  {
    img: '/images/resumeTemplates/ditto.jpg',
    name: 'ditto',
  },
  { img: '/images/resumeTemplates/gengar.jpg', name: 'gengar' },
  { img: '/images/resumeTemplates/kakuna.jpg', name: 'kakuna' },
  { img: '/images/resumeTemplates/leafish.jpg', name: 'leafish' },
  { img: '/images/resumeTemplates/onyx.jpg', name: 'onyx' },
  { img: '/images/resumeTemplates/pikachu.jpg', name: 'pikachu' },
  { img: '/images/resumeTemplates/rhyhorn.jpg', name: 'rhyhorn.' },
];

export default templates;
