import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoader } from "../components/common/Loader";
import { useDispatch } from "react-redux";
import { authSlice } from "../store";

export default function LoginPage({ location }) {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    error,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redirect to auth0 for authentication page if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      navigate(location);
    }
  }, [isAuthenticated, navigate, location, loginWithRedirect]);

  // Set user credentials
  useEffect(() => {
    if (user) {
      dispatch(authSlice.actions.setCredentials(user));
    }
  }, [user, dispatch]);

  // Get user metadata
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "{yourDomain}";

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://www.revats.ai/`,
            scope: "read:current_user",
          },
        });

        const url = `https://l32eev1a8d.execute-api.us-east-1.amazonaws.com/api/private`;

        const metadataResponse = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const res = await metadataResponse.json();
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) return <SpinnerLoader />;

  if (error) {
    if (typeof error === "object" && error.message) {
      return <div>Oops... {error.message}</div>;
    } else {
      return <div>Oops... Something went wrong</div>;
    }
  }

  return null;
}

LoginPage.defaultProps = {
  location: "/user-profile",
};
