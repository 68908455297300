import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CheckSquare, Edit } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateResumeMutation } from '../../store';
import { projectSlice } from '../../store';
import { SpinnerLoader } from '../common/Loader';

const ProjectTitle = () => {
  const { resumeObj, resumeTitle } = useSelector(
    (state) => state.project
  );

  useEffect(() => {
    dispatch(projectSlice.actions.setResumeTitle(resumeObj?.title));
  }, []);

  const [edit, setEdit] = useState(false);
  const [
    updateResume,
    {
      data: editedResume,
      isLoading: editIsLoading,
      error: editIseError,
    },
  ] = useUpdateResumeMutation();

  const { page } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const handleTitleChange = (e) => {
    dispatch(projectSlice.actions.setResumeTitle(e.target.value));
    setEdit(true);
  };

  const handleTitleSubmit = async (e) => {
    e.preventDefault();
    setEdit(false);
    const jsonData = JSON.stringify(resumeObj);
    await updateResume({
      id: resumeObj.id,
      resume: { content: jsonData, title: resumeTitle },
    });
    dispatch(
      projectSlice.actions.setResumeObj({
        ...resumeObj,
        title: resumeTitle,
      })
    );
  };

  return (
    <>
      {editIsLoading && <SpinnerLoader />}
      <div className="w-full space-y-2 lg:space-y-8">
        <div className="space-y-2 lg:space-y-4">
          <div className="text-[#737373] text-sm mb-1 lg:mb-2">
            <Link to="/user-profile" className="text-[#00B3FF]">
              Home
            </Link>{' '}
            / New Project
          </div>
          {editIseError && (
            <div className="text-red-500">An error occurred...</div>
          )}
          {(resumeTitle || resumeTitle === '') && (
            <div className="flex items-center text-[#00B3FF] text-2xl lg:text-4xl font-normal w-full">
              <input
                id="resumeTitle"
                value={resumeTitle}
                type="text"
                onChange={handleTitleChange}
                className="w-7/12 max-w-full outline-none border-none bg-transparent text-[#00B3FF] text-2xl lg:text-4xl font-normal"
              />
              {!edit ? (
                <label htmlFor="resumeTitle">
                  <Edit />
                </label>
              ) : (
                <button onClick={handleTitleSubmit}>
                  <CheckSquare />
                </button>
              )}
            </div>
          )}
        </div>
        {page !== 3 && (
          <div className="hidden lg:block text-black font-normal text-2xl">
            2. On the right panel, select the suggestions you would
            like to apply
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectTitle;
