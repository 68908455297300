import React, { useState, useEffect } from 'react';
import QuillEditor from '../QuillEditor';
import { LaunchAppButton } from '../../Buttons';
import { projectSlice } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in/lib/FadeIn';

const JobDescriptionForm = () => {
  const [isTextAreaFocused, setTextAreaFocus] = useState(false);
  const [isTextAreaEmpty, setTextAreaEmpty] = useState(true);
  const [displayInstructions, setDisplayInstructions] =
    useState(true);
  const [classes, setClasses] = useState(
    // 'relative flex flex-col justify-between px-2 pt-2  w-[300px] h-[160px] lg:w-[416px] lg:h-[256px]  2xl:w-[620px] 2xl:h-[380px] border-brand-purple-dark border-[1px] rounded-[30px] bg-[#01021C] text-[#9494E5] '
    'relative flex flex-col justify-between px-2 pt-2  w-[400px] h-[260px] lg:w-[500px] lg:h-[456px]  2xl:w-[620px] 2xl:h-[580px] border-brand-purple-dark border-[1px] rounded-[30px] bg-[#01021C] text-[#9494E5] '
  );

  const dispatch = useDispatch();
  const { job, resume } = useSelector((state) => state.project);

  // setTextAreaEmpty to false if jobDescription is not empty
  useEffect(() => {
    if (job) {
      if (job.length > 0) {
        setTextAreaEmpty(false);
      } else {
        setTextAreaEmpty(true);
      }
    } else if (job === '' || job === null) {
      setTextAreaEmpty(true);
    }
  }, [job]);

  // remove instructions if user is typing
  useEffect(() => {
    if (isTextAreaEmpty === false && displayInstructions === true) {
      setDisplayInstructions(false);
    } else if (isTextAreaEmpty === true) {
      setDisplayInstructions(true);
    }
  }, [isTextAreaEmpty, isTextAreaFocused]);

  // add heart-beat-purple(animation) to job description card class if resume is uploaded
  useEffect(() => {
    if (resume && displayInstructions) {
      setClasses(
        'heart-beat-purple relative flex flex-col justify-between items-center px-2 pt-2  w-[300px] h-[160px] lg:w-[516px] lg:h-[356px]  2xl:w-[620px] 2xl:h-[380px] border-brand-purple-dark border-[1px] rounded-[30px] bg-[#01021C] text-[#9494E5] '
      );
    } else {
      setClasses(
        'relative flex flex-col justify-between items-center px-2 pt-2 w-[300px] h-[160px] lg:w-[516px] lg:h-[356px]  2xl:w-[620px] 2xl:h-[380px] border-brand-purple-dark border-[1px] rounded-[30px] bg-[#01021C] text-[#9494E5] '
      );
    }
  }, [resume, displayInstructions]);

  // update jobDescription in store
  const handleEditorChange = (content, delta, source, editor) => {
    const plainText = editor.getText(); // Get plain text content
    setJobDescription(plainText); // Store the plain text content
  };

  // updates jobDescription in store
  const setJobDescription = (jobDescription) => {
    dispatch(projectSlice.actions.setJob(jobDescription));
  };

  return (
    <FadeIn className={classes} id="myEditor">
      <QuillEditor
        setJobDescription={setJobDescription}
        jobDescription={job}
        setTextAreaFocus={setTextAreaFocus}
        onChange={handleEditorChange}
      />

      {isTextAreaEmpty ? (
        <span className=" text-opacity-40 border-l-[1px] absolute top-0 left-0 w-full border-brand-purple-light/50 text-brand-purple-light pl-2 mt-5 opacity-60 mx-7">
          Copy the job description and paste it here ...
        </span>
      ) : (
        ''
      )}

      {isTextAreaEmpty && (
        <div className="mt-[-36px]">
          <img src="/images/icons/cv.png" alt="upload" />
        </div>
      )}
      <div className="" />

      <div className="absolute right-4 lg:bottom-[-44px] 2xl:bottom-4">
        {!displayInstructions && resume && <LaunchAppButton />}
      </div>
    </FadeIn>
  );
};

export default JobDescriptionForm;
