import React, { useRef, useState, useEffect } from "react";
import { Repeat, X } from "react-feather";
import formatBytes from "../../../utils/formatBytes";
import { useDispatch, useSelector } from "react-redux";
import { projectSlice } from "../../../store";
import FadeIn from "react-fade-in/lib/FadeIn";

const UploadResume = () => {
  const dispatch = useDispatch();
  const { resume } = useSelector((state) => state.project);

  const [classes, setClasses] = useState(
    "heart-beat-blue relative w-[300px] h-[160px] lg:w-[516px] lg:h-[356px] 2xl:w-[620px] 2xl:h-[380px] border-brand-blue/50 border-[1px] rounded-[30px] bg-[#0B0C27] text-brand-cyan flex flex-col py-10 px-6 font-medium"
  );
  const fileInput = useRef();
  const fileInput2 = useRef();

  const [fileSize, setFileSize] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    if (resume) {
      const size = formatBytes(resume.size, 0);
      setFileSize(size);
    }
  }, [resume]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    resetForm();
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    dispatch(projectSlice.actions.setResume(files[0]));
    setClasses(
      " relative w-[300px] h-[160px] lg:w-[516px] lg:h-[356px] 2xl:w-[620px] 2xl:h-[380px] border-brand-blue/50 border-[1px] rounded-[30px] bg-[#0B0C27] text-brand-cyan flex flex-col py-10 px-6 font-medium"
    );
    setIsDragOver(false); // Reset drag over state
  };

  // handle submit for uploading file for the first time
  const handleUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    dispatch(projectSlice.actions.setResume(file));
    setClasses(
      " relative w-[300px] h-[160px] lg:w-[516px] lg:h-[356px] 2xl:w-[620px] 2xl:h-[380px] border-brand-blue/50 border-[1px] rounded-[30px] bg-[#0B0C27] text-brand-cyan flex flex-col py-10 px-6 font-medium"
    );
    setIsDragOver(false); // Reset drag over state
  };


  // handle submit for uploading new file
  const handleUpload2 = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    dispatch(projectSlice.actions.setResume(file));
    setIsDragOver(false); // Reset drag over state
  };

  const resetForm = () => {
    dispatch(projectSlice.actions.setResume(null));
  };

  return (
    <FadeIn>
      <form className={`${classes} ${isDragOver ? "drag-over-effect" : ""}`}>
        {!resume ? (
          <label
            htmlFor="resume"
            className=" text-base flex flex-col  border-brand-cyan/30 w-full h-full py-5 justify-around items-center text-center cursor-pointer "
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <span className="flex h-full w-full text-center items-center justify-center">

            <img src="/images/icons/docs3.svg" alt="upload" />
            <span className="text-sm" style={{ color: '#2d9cdb' }}>Click to upload your resume</span>

            </span>
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".doc,.docx,application/pdf"
              ref={fileInput}
              onChange={handleUpload}
              className="hidden"
            />
          </label>
        ) : (
          <div className="flex flex-col w-full h-full justify-center space-y-12 items-center rounded-[30px] border-dashed border-[0px]  border-brand-cyan/30">
            <span className="lg:text-xl text-center p-2 w-full break-words">
              {resume?.name} / {fileSize} 
            </span>
            <div className="flex justify-around items-center w-full text-base">
              <button
                className="text-brand-red flex justify-start items-center space-x-1"
                onClick={resetForm}
              >
                <span className="cursor-pointer text-sm">Remove file </span>{" "}
                <X />
              </button>
              <label
                htmlFor="resume2"
                className="text-brand-cyan flex justify-start items-center space-x-1"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <span className="text-sm">Upload new file </span>
                <Repeat />
                <input
                  type="file"
                  id="resume2"
                  name="resume"
                  accept=".doc,.docx,application/pdf"
                  ref={fileInput2}
                  onChange={handleUpload2}
                  className="hidden"
                />
              </label>
            </div>
          </div>
        )}

        {resume && (
          <img
            src="/images/icons/checkbox-cyan.png"
            alt="arrow"
            className="absolute bottom-2 right-6"
          />
        )}
      </form>
    </FadeIn>
  );
};

export default UploadResume;
