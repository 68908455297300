import React, { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SpinnerLoader } from '../Loader';
import Portal from '../Portal';
import {
  projectSlice,
  useUploadPdfMutation,
  authSlice,
} from '../../../store';
import { fetchTask } from '../../../utils/fetchTask';
import useScreenWidth from '../../../hooks/useScreenWidth';
import { motion, AnimatePresence } from 'framer-motion';

const UploadNewResumeBtn = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [taskFailed, setTaskFailed] = useState(false);

  const fileInput = useRef();
  const fileInput2 = useRef();

  const { resume, resumeTitle, activeResume } = useSelector(
    (state) => state.project
  );
  const { user } = useSelector((state) => state.auth);

  const { getAccessTokenSilently } = useAuth0();
  const [uploadPdf] = useUploadPdfMutation();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loaderMsg = 'Preparing your resume...';

  const screenWidth = useScreenWidth();

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const file = fileInput.current.files[0];
    if (!file) return;

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });
      dispatch(authSlice.actions.setAccessToken(accessToken));

      let res = await uploadPdf(file);
      if (res.error) {
        throw new Error('Upload failed');
      }

      res = await fetchTask(res.data.task, accessToken);
      if (res.status === 'done') {
        dispatch(projectSlice.actions.setResumeObj(res.payload));
        navigate('/project-view', {
          state: { from: location.pathname },
          replace: true,
        });
      } else {
        setTaskFailed(true);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to upload resume');
      setTaskFailed(true);
    } finally {
      setLoading(false);
      cleanUpMemory();
    }
  };

  const handleUpload = (event) => {
    event.preventDefault();
    const file = fileInput.current.files[0];
    dispatch(projectSlice.actions.setResume(file));
    handleSubmit();
  };

  const resetForm = () => {
    dispatch(projectSlice.actions.setResume(null));
  };

  const cleanUpMemory = () => {
    dispatch(projectSlice.actions.setResume(null));
    localStorage.removeItem('resume');
    localStorage.removeItem('job');
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      dispatch(projectSlice.actions.setResume(file));
      handleSubmit();
    }
  };

  return (
    <>
      <motion.label
        htmlFor="resume"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className={`
          cursor-pointer
          flex min-w-[200px] w-full justify-center items-center
          border-dashed border rounded-3xl border-[#167aef52]
          bg-[#F6FCFF] transition-all duration-200 hover:border-[#167aef80]
          ${activeResume ? 'py-6 px-3' : 'md:h-[180px] py-6 px-3'}
          ${isDragOver ? 'border-[#167aef] bg-[#F0F9FF]' : ''}
        `}
      >
        <input
          type="file"
          id="resume"
          accept=".pdf,.doc,.docx"
          onChange={handleUpload}
          ref={fileInput}
          className="hidden"
        />
        <AnimatePresence mode="wait">
          {activeResume ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex justify-center items-center gap-4"
            >
              <div className="w-5 h-6 flex justify-center items-center">
                <img
                  src="/images/icons/doc.svg"
                  alt="Upload Resume"
                  className="w-full h-full object-contain"
                />
              </div>
              <span className="text-sm text-[#64A6EF] font-normal">
                Upload New Resume
              </span>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col md:flex-row justify-center items-center gap-4"
            >
              <div className="w-5 h-6 flex justify-center items-center">
                <img
                  src="/images/icons/doc.svg"
                  alt="Upload Resume"
                  className="w-full h-full object-contain"
                />
              </div>
              <span className="text-[#64A6EF] font-normal">
                Upload New Resume
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.label>

      <AnimatePresence>
        {loading && (
          <Portal>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 z-[9999] flex items-center justify-center"
            >
              <SpinnerLoader text={loaderMsg} withBox={true} />
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};

export const UploadNewResumeBtnPlain = () => {
  const [loading, setLoading] = useState(false);
  const fileInput = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [uploadPdf] = useUploadPdfMutation();
  const loaderMsg = 'Preparing your resume...';

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const file = fileInput.current.files[0];
    if (!file) return;

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });
      dispatch(authSlice.actions.setAccessToken(accessToken));

      let res = await uploadPdf(file);
      if (res.error) {
        throw new Error('Upload failed');
      }

      res = await fetchTask(res.data.task, accessToken);
      if (res.status === 'done') {
        dispatch(projectSlice.actions.setResumeObj(res.payload));
        navigate('/project-view', {
          state: { from: location.pathname },
          replace: true,
        });
      } else {
        toast.error('Failed to process resume');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to upload resume');
    } finally {
      setLoading(false);
      dispatch(projectSlice.actions.setResume(null));
      localStorage.removeItem('resume');
      localStorage.removeItem('job');
    }
  };

  const handleUpload = (event) => {
    event.preventDefault();
    const file = fileInput.current.files[0];
    dispatch(projectSlice.actions.setResume(file));
    handleSubmit();
  };

  return (
    <>
      <label
        htmlFor="resume-plain"
        className=" flex w-auto justify-center items-center cursor-pointer"
      >
        <input
          type="file"
          id="resume-plain"
          accept=".pdf,.doc,.docx"
          onChange={handleUpload}
          ref={fileInput}
          className="hidden"
        />
        <div className="flex justify-center items-center gap-2">
          <div className=" h-4 flex justify-center items-center">
            <img
              src="/images/icons/doc.svg"
              alt="Upload Resume"
              className="w-full h-full object-contain"
            />
          </div>
          <span className="text-sm text-[#64A6EF] font-normal">
            Upload New Resume
          </span>
        </div>
      </label>

      {loading && (
        <Portal>
          <div className="fixed inset-0 bg-black/50 z-[9999] flex items-center justify-center">
            <SpinnerLoader text={loaderMsg} withBox={true} />
          </div>
        </Portal>
      )}
    </>
  );
};

export default UploadNewResumeBtn;
