import React, { useEffect } from "react";
import Applications from "./Applications";
import DashboardTitle from "./DashboardTitle";
import { CreateNewApplication, UpgradePlan } from "../common/Buttons";
import { authSlice, useResumesMutation, projectSlice } from "../../store";
import { SpinnerLoader } from "../common/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

const Resumes = React.memo(() => {
  const [getResumes, { data, isLoading, isError }] = useResumesMutation();

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchResumes = async () => {
      const token = await getAccessTokenSilently();
      dispatch(authSlice.actions.setAccessToken(token));
      getResumes();
    };
    fetchResumes();
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(projectSlice.actions.setResumes(data.user.resumes));
    }
  }, [data, dispatch]);

  const item = (
    <div className="flex justify-center space-x-2">
      <CreateNewApplication />
      <UpgradePlan />
    </div>
  );

  return (
    <div className="w-full">
      <DashboardTitle item={item} />
      {isError && (
        <div className="text-red-500 text-center">
          Oops... An Error Occurred
        </div>
      )}
      {isLoading && <SpinnerLoader />}
      {data && <Applications />}
    </div>
  );
});

export default Resumes;
