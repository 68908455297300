import React, { useCallback } from 'react';
import TextInput from './TextInput';

const Location = React.memo(({ data, setData }) => {
  const location = data.location || {};

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setData((prevData) => ({
        ...prevData,
        location: { ...prevData.location, [name]: value },
      }));
    },
    [setData]
  );

  return (
    <div className="w-full">
      <div className="w-full flex flex-col md:flex-row space-x-2">
        <TextInput
          label="City"
          type="text"
          name="city"
          value={location.city || ''}
          handleChange={handleChange}
        />
        <TextInput
          label="Region"
          type="text"
          name="region"
          value={location.region || ''}
          handleChange={handleChange}
        />
      </div>
      <div className="w-full flex flex-col md:flex-row space-x-2">
        <TextInput
          label="Address"
          type="text"
          name="address"
          value={location.address || ''}
          handleChange={handleChange}
        />
        <TextInput
          label="Country"
          type="text"
          name="country"
          value={location.country || ''}
          handleChange={handleChange}
        />
      </div>
      <TextInput
        label="Postal Code"
        type="text"
        name="postalCode"
        value={location.postalCode || ''}
        handleChange={handleChange}
      />
    </div>
  );
});

export default Location;
