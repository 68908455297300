import React from "react";
import { ReactComponent as Brain } from "../assets/icons/brain.svg";
import { ReactComponent as SuitCase } from "../assets/icons/suitcase.svg";
import { ReactComponent as Other } from "../assets/icons/other.svg";
import { ReactComponent as Profile } from "../assets/icons/profile.svg";
import { ReactComponent as Tabler } from "../assets/icons/tabler.svg";

const resumeTabs = [
  {
    name: "Personal information",
    ref: "section1",
    icon: <Profile />,
    selected: true,
  },
  {
    name: "Skills",
    ref: "section2",
    icon: <Brain />,
    selected: false,
  },
  {
    name: "Education",
    ref: "section3",
    icon: <Tabler />,
    selected: false,
  },
  {
    name: "Work experience",
    ref: "section4",
    icon: <SuitCase />,
    selected: false,
  },
  {
    name: "Other",
    ref: "section5",
    icon: <Other />,
    selected: false,
  },
];

export default resumeTabs;
