import { useEffect } from "react";

const useCloseOnOutsideClick = ({ menuRef, showMenu, setShowMenu }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    // Always listen when the component is mounted
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener when the component unmounts or showMenu changes
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuRef, setShowMenu]);
};

export default useCloseOnOutsideClick;
