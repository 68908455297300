import React, { useState } from 'react';
import { DashboardLayoutCols2 } from '../Layouts';
import { JobDescriptionFormLarge } from '../common/Forms';
import DashboardTitle from './DashboardTitle';
import { StepTracker } from '../common/StepTracker';
import styled from 'styled-components';
import ProjectStepMobile from '../common/ProjectStepMobile';

const JobDescription = ({ goPrevious, goNext }) => {
  const [jobDescription, setJobDescription] = useState('');

  const currentStep = (
    <div className="w-full">
      <div className="flex md:hidden">
        <ProjectStepMobile>
          2. Provide job description
        </ProjectStepMobile>
      </div>
      <div className="hidden md:flex w-full md:justify-end 2xl:justify-center items-center">
        <StepTracker theme="green" />
      </div>
    </div>
  );

  return (
    <div className="w-full mb-6 ">
      <DashboardTitle item={currentStep} itemType="stepper" />
      <DashboardLayoutCols2
        RightComponent={
          <Next goPrevious={goPrevious} goNext={goNext} />
        }
        LeftComponent={
          <JobDescriptionFormLarge
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
          />
        }
      />
    </div>
  );
};

const Next = ({ goNext, goPrevious }) => {
  return (
    <RightComponentWrapper className="relative pt-12 md:pt-0 mt-[-10px]">
      <div className="flex flex-col justify-center space-y-6 h-full px-8">
        <div className="flex flex-row md:flex-col justify-center  w-full md:justify-center items-center md:space-y-6 ">
          <div className="flex justify-center items-center text-brand-cyan-dark rounded-full  border-brand-cyan-dark border min-w-[36px] min-h-[36px] w-[36px] h-[36px] md:w-[52px] md:h-[52px] mr-2 md:mr-0">
            2
          </div>
          <div className="text-[#090909] md:text-center flex justify-center items-center">
            Kindly add the job description and click the compare
            button.
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <button
            onClick={goNext}
            className="rounded bg-brand-cyan-dark w-full border border-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] text-white"
          >
            Yes, Compare Now
          </button>
          <button
            onClick={goPrevious}
            className="text-brand-blue flex justify-center space-x-3 items-center px-8 py-3 border-[#BDBDBD] border rounded w-full lg:w-auto underline lg:no-underline"
          >
            <div>
              <img
                src="/images/icons/reload.svg"
                alt="undo icon"
                className="w-[12px] h-[12px] color-brand-blue"
              />
            </div>
            <span>Back to resume</span>
          </button>
        </div>
      </div>
    </RightComponentWrapper>
  );
};

const RightComponentWrapper = styled.div`
  background: #fff;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.04);

  @media (min-width: 768px) {
    background-color: transparent;
    box-shadow: none;
  }
`;
export default JobDescription;
