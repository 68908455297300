import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    downloadSuccessful: false,
  },
  reducers: {
    setDownloadSuccessful: (state, { payload }) => {
      state.downloadSuccessful = payload;
    },
  },
});

export default modalSlice;
