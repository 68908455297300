import React, { useState, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch, useSelector } from 'react-redux';
import { projectSlice, authSlice } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteResumeMutation,
  useSetDefaultResumeMutation,
} from '../../store';
import { SpinnerLoader } from './Loader';
import Portal from './Portal';
import ModalWrapper from './StyledComponents/ModalWrapper.styled';
import {
  ResumeTemplates,
  DownloadSuccessfulCard,
  ActiveResumeTag,
} from './Cards';
import { useResumesMutation } from '../../store';
import { useAuth0 } from '@auth0/auth0-react';
import { TrailEndedCard } from './Cards';

const ApplicationCard = ({
  applicationThemeColor = '#000000',
  title,
  lastUpdated,
  content,
  id,
  defaultResume,
}) => {
  const { hasActiveSubscription, freeUses } = useSelector(
    (state) => state.auth
  );
  const { activeResume, defaultResume: defaultResumeObj } =
    useSelector((state) => state.project);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] =
    React.useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { downloadSuccessful } = useSelector((state) => state.modal);
  const [resume] = useState(JSON.parse(content));
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [
    getResumes,
    {
      data: resumes,
      isLoading: resumesIsLoading,
      isError: resumesError,
    },
  ] = useResumesMutation();

  const [deleteResume, { isLoading, data }] =
    useDeleteResumeMutation();
  const [
    setDefaultResume,
    {
      isLoading: setDefaultResumeIsLoading,
      data: setDefaultResumeResponse,
    },
  ] = useSetDefaultResumeMutation();

  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showMenu]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  //edit resume
  const editResume = () => {
    if (!hasActiveSubscription && freeUses >= 2) {
      setUpgradeModalIsOpen(true);
      return;
    }
    dispatch(
      projectSlice.actions.setResumeObj({
        ...resume,
        id: id,
        title: title,
      })
    );
    dispatch(projectSlice.actions.setPage(1));
    navigate('/project-view');
  };

  //download resume
  const downloadResume = () => {
    dispatch(
      projectSlice.actions.setEnhancedResume({
        ...resume,
        id: id,
        title: title,
      })
    );
    dispatch(projectSlice.actions.setResumeId(id));
    setModalIsOpen(true);
  };

  //delete resume
  const handleDeleteResume = async () => {
    const res = await deleteResume(id);

    if (res.data.resume === 'Deleted') {
      dispatch(projectSlice.actions.removeResume(id));

      if (activeResume?.id === id) {
        dispatch(projectSlice.actions.setActiveResume(null));
      }
      if (defaultResumeObj?.id === id) {
        dispatch(projectSlice.actions.setDefaultResume(null));
      }
    }

    const fetchResumes = async () => {
      const token = await getAccessTokenSilently();
      dispatch(authSlice.actions.setAccessToken(token));
      getResumes();
    };

    fetchResumes();
  };

  // set default resume
  const handleSetDefaultResume = async () => {
    const token = await getAccessTokenSilently();
    dispatch(authSlice.actions.setAccessToken(token));
    const res = await setDefaultResume(id);
    if (res.data.user.default_resume !== -1) {
      const defaultResumeID = res.data.user.default_resume;
      const defaultResume = res.data.user.resumes.find(
        (resume) => resume.id === defaultResumeID
      );
      dispatch(projectSlice.actions.setDefaultResume(defaultResume));
      dispatch(
        projectSlice.actions.setResumes(res.data.user.resumes)
      );
    }
  };

  if (resumes) {
    dispatch(projectSlice.actions.setResumes(resumes.user.resumes));
  }

  return (
    <>
      {resumesError && (
        <div className="flex justify-between items-center w-full ">
          An error occured
        </div>
      )}

      {(isLoading ||
        resumesIsLoading ||
        setDefaultResumeIsLoading) && <SpinnerLoader />}

      <FadeIn className="flex justify-between relative items-center w-full ">
        <div className="flex w-[464] justify-center items-center">
          <div
            className="flex justify-center items-end w-[76px] md:w-[168px] h-[56px] md:h-[120px]"
            style={{
              backgroundColor: applicationThemeColor,
            }}
          >
            <img
              src="images/dashboard/application.png"
              alt="application"
              className="w-[56px] h-[44px] md:w-[120px] md:h-24"
            />
          </div>
          <div className="flex flex-col text-left items-start justify-center md:pl-3 pl-4">
            {defaultResume && <ActiveResumeTag />}
            <div className="flex justify-start items-center  text-[20px] text-brand-blue opacity-80 space-x-[2px]">
              <span>
                <img src="images/icons/pdf.svg" alt="pdf" />
              </span>
              <span className="font-[600] md:text-lg text-xs text-left">
                {title}
              </span>
            </div>
            <div>
              <span className="text-[#828282] text-[10px] md:text-[14px]  font-[400]">
                last edited{' '}
              </span>
              <span className="text-[10px] md:text-[14px] text-brand-blue">
                {lastUpdated}
              </span>
            </div>
          </div>
        </div>
        <div className="absolute right-0">
          <div className="hidden lg:block">
            <ActionBtns
              editResume={editResume}
              downloadResume={downloadResume}
              deleteResume={handleDeleteResume}
              handleSetDefaultResume={handleSetDefaultResume}
              setUpgradeModalIsOpen={setUpgradeModalIsOpen}
            />
          </div>
          <button
            onClick={() => setShowMenu(true)}
            className="lg:hidden h-[24px] w-[24px] md:w-[18px] md:h-[18px] relative"
          >
            <img
              src="images/icons/menu.svg"
              alt="menu"
              className="w-full h-full"
            />
            {showMenu && (
              <div
                ref={menuRef}
                className="absolute top-2 right-6 bg-white shadow-lg rounded-lg p-4 w-[200px] z-[100000]"
              >
                <ActionBtns
                  editResume={editResume}
                  downloadResume={downloadResume}
                  deleteResume={handleDeleteResume}
                  handleSetDefaultResume={handleSetDefaultResume}
                  setUpgradeModalIsOpen={setUpgradeModalIsOpen}
                />
              </div>
            )}
          </button>
        </div>
      </FadeIn>
      {modalIsOpen && (
        <Portal>
          <ModalWrapper>
            <ResumeTemplates
              closeModal={closeModal}
              modalIsOpen={modalIsOpen}
            />
          </ModalWrapper>
        </Portal>
      )}
      {downloadSuccessful && (
        <Portal>
          <ModalWrapper>
            <DownloadSuccessfulCard />
          </ModalWrapper>
        </Portal>
      )}
      {upgradeModalIsOpen && (
        <Portal>
          <ModalWrapper className="w-full h-full">
            <TrailEndedCard
              setUpgradeModalIsOpen={setUpgradeModalIsOpen}
            />
          </ModalWrapper>
        </Portal>
      )}
    </>
  );
};

const ActionBtns = ({
  editResume,
  downloadResume,
  deleteResume,
  handleSetDefaultResume,
  setUpgradeModalIsOpen,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showMenu]);
  return (
    <div className="flex flex-col lg:flex-row justify-end items-start space-y-8 lg:space-x-10 lg:space-y-0 text-brand-cyan-dark ">
      <button
        onClick={editResume}
        className="flex justify-start items-center space-x-3"
      >
        <div className=" h-[24px] w-[24px] md:w-[18px] md:h-[18px]">
          <img
            src="images/icons/edit.svg"
            alt="edit"
            className="w-full h-full"
          />
        </div>
        <span className="lg:hidden 2xl:block">Edit</span>
      </button>
      <button
        onClick={downloadResume}
        className=" flex justify-start items-center space-x-3"
      >
        <div className=" h-[24px] w-[24px] md:w-[18px] md:h-[18px]">
          <img
            src="images/icons/download.svg"
            alt="download"
            className="w-full h-full"
          />
        </div>
        <span className="lg:hidden 2xl:block">Download</span>
      </button>
      <button
        onClick={deleteResume}
        className="flex justify-start items-center space-x-3"
      >
        <div className=" h-[24px] w-[24px] md:w-[18px] md:h-[18px]">
          <img
            src="images/icons/delete.svg"
            alt="delete"
            className="w-full h-full"
          />
        </div>
        <span className="lg:hidden 2xl:block">Delete</span>
      </button>
      <button
        onClick={() => setShowMenu(true)}
        className=" hidden lg:flex justify-start items-center space-x-3"
      >
        <div className="h-[24px] w-[24px] md:w-[18px] md:h-[18px]">
          <img
            src="images/icons/menu.svg"
            alt="menu"
            className="w-full h-full"
          />
        </div>
      </button>

      <button
        className="md:hidden flex justify-start items-center space-x-3"
        onClick={handleSetDefaultResume}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 10 10"
          fill="none"
        >
          <g clipPath="url(#clip0_1834_4475)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.26235 1.19971C7.46395 1.19971 7.6573 1.27979 7.79985 1.42235C7.9424 1.5649 8.02249 1.75824 8.02249 1.95984V5.76052H5.55205C5.40085 5.76052 5.25584 5.82058 5.14893 5.9275C5.04201 6.03441 4.98195 6.17942 4.98195 6.33062V8.80106H2.70154C2.49994 8.80106 2.3066 8.72097 2.16404 8.57842C2.02149 8.43587 1.94141 8.24252 1.94141 8.04092V1.95984C1.94141 1.75824 2.02149 1.5649 2.16404 1.42235C2.3066 1.27979 2.49994 1.19971 2.70154 1.19971H7.26235ZM8.00614 6.52065C7.9756 6.66465 7.90391 6.79669 7.79977 6.90072L6.12215 8.57834C6.01812 8.68248 5.88608 8.75418 5.74208 8.78472V6.52065H8.00614ZM4.22181 4.62032H3.84174C3.74094 4.62032 3.64427 4.66036 3.573 4.73163C3.50172 4.80291 3.46168 4.89958 3.46168 5.00038C3.46168 5.10118 3.50172 5.19785 3.573 5.26913C3.64427 5.34041 3.74094 5.38045 3.84174 5.38045H4.22181C4.32261 5.38045 4.41928 5.34041 4.49056 5.26913C4.56184 5.19785 4.60188 5.10118 4.60188 5.00038C4.60188 4.89958 4.56184 4.80291 4.49056 4.73163C4.41928 4.66036 4.32261 4.62032 4.22181 4.62032ZM6.12215 3.10004H3.84174C3.74094 3.10004 3.64427 3.14009 3.573 3.21136C3.50172 3.28264 3.46168 3.37931 3.46168 3.48011C3.46168 3.58091 3.50172 3.67758 3.573 3.74886C3.64427 3.82014 3.74094 3.86018 3.84174 3.86018H6.12215C6.22295 3.86018 6.31962 3.82014 6.3909 3.74886C6.46217 3.67758 6.50222 3.58091 6.50222 3.48011C6.50222 3.37931 6.46217 3.28264 6.3909 3.21136C6.31962 3.14009 6.22295 3.10004 6.12215 3.10004Z"
              fill="#EF7116"
            />
          </g>
          <defs>
            <clipPath id="clip0_1834_4475">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.419922 0.439453)"
              />
            </clipPath>
          </defs>
        </svg>
        <span>Make Default</span>
      </button>

      {showMenu && (
        <div
          ref={menuRef}
          className="absolute top-2 right-6 bg-white shadow-lg rounded-lg p-8  flex flex-col justify-start items-start space-y-4 w-[200px] z-[10000000]"
        >
          <button
            className="flex justify-start items-center space-x-3"
            onClick={editResume}
          >
            <img
              src="images/icons/edit.svg"
              alt="edit"
              className="w-full h-full"
            />
            <span>Continue</span>
          </button>

          <button
            className="flex justify-start items-center space-x-3"
            onClick={handleSetDefaultResume}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 10 10"
              fill="none"
            >
              <g clipPath="url(#clip0_1834_4475)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.26235 1.19971C7.46395 1.19971 7.6573 1.27979 7.79985 1.42235C7.9424 1.5649 8.02249 1.75824 8.02249 1.95984V5.76052H5.55205C5.40085 5.76052 5.25584 5.82058 5.14893 5.9275C5.04201 6.03441 4.98195 6.17942 4.98195 6.33062V8.80106H2.70154C2.49994 8.80106 2.3066 8.72097 2.16404 8.57842C2.02149 8.43587 1.94141 8.24252 1.94141 8.04092V1.95984C1.94141 1.75824 2.02149 1.5649 2.16404 1.42235C2.3066 1.27979 2.49994 1.19971 2.70154 1.19971H7.26235ZM8.00614 6.52065C7.9756 6.66465 7.90391 6.79669 7.79977 6.90072L6.12215 8.57834C6.01812 8.68248 5.88608 8.75418 5.74208 8.78472V6.52065H8.00614ZM4.22181 4.62032H3.84174C3.74094 4.62032 3.64427 4.66036 3.573 4.73163C3.50172 4.80291 3.46168 4.89958 3.46168 5.00038C3.46168 5.10118 3.50172 5.19785 3.573 5.26913C3.64427 5.34041 3.74094 5.38045 3.84174 5.38045H4.22181C4.32261 5.38045 4.41928 5.34041 4.49056 5.26913C4.56184 5.19785 4.60188 5.10118 4.60188 5.00038C4.60188 4.89958 4.56184 4.80291 4.49056 4.73163C4.41928 4.66036 4.32261 4.62032 4.22181 4.62032ZM6.12215 3.10004H3.84174C3.74094 3.10004 3.64427 3.14009 3.573 3.21136C3.50172 3.28264 3.46168 3.37931 3.46168 3.48011C3.46168 3.58091 3.50172 3.67758 3.573 3.74886C3.64427 3.82014 3.74094 3.86018 3.84174 3.86018H6.12215C6.22295 3.86018 6.31962 3.82014 6.3909 3.74886C6.46217 3.67758 6.50222 3.58091 6.50222 3.48011C6.50222 3.37931 6.46217 3.28264 6.3909 3.21136C6.31962 3.14009 6.22295 3.10004 6.12215 3.10004Z"
                  fill="#EF7116"
                />
              </g>
              <defs>
                <clipPath id="clip0_1834_4475">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.419922 0.439453)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span>Make Default</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ApplicationCard;
