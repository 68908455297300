import React from 'react';
import { Link } from 'react-router-dom';

const UpgradePlan = () => {
  return (
    <Link
      to="/subscription"
      className="w-[186px] h-[48px] flex justify-center items-center bg-transparent text-brand-dark dark:text:white border-[#BDBDBD] border-[1px] rounded-[4px] text-[14px] dark:text-white"
    >
      Upgrade Plan
    </Link>
  );
};

const UpgradePlanBtnWithImage = () => {
  return (
    <Link
      to="/subscription"
      className="flex items-center rounded-lg border border-[#2D9CDB] text-[#2D9CDB] text-sm bg-transparent px-6 py-3 space-x-2
                 hover:bg-[#2D9CDB] hover:text-white hover:shadow-xl transition-all duration-300 ease-in-out"
    >
      <img src="/images/icons/crown.svg" alt="Upgrade Plan" />
      <span className="hidden lg:block">
        Unlimited access with Premium
      </span>
    </Link>
  );
};

export { UpgradePlan, UpgradePlanBtnWithImage };

export default UpgradePlan;
