import React, { useRef, useState } from 'react';
import { StepTracker } from '../StepTracker';
import { useDispatch, useSelector } from 'react-redux';
import { projectSlice, useUploadPdfMutation } from '../../../store';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { authSlice } from '../../../store';
import { useAuth0 } from '@auth0/auth0-react';
import { SpinnerLoader } from '../Loader';
import { fetchTask } from '../../../utils/fetchTask';

const Container = styled.div`
  box-shadow: ${({ location }) =>
    location === '/user-profile' && '0px 10px 13px 0px #5871841a'};
  -webkit-box-shadow: ${({ location }) =>
    location === '/user-profile' && '0px 10px 13px 0px #5871841a'};
`;

const UploadResumeCard = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadPdf, { data, isLoading, error }] =
    useUploadPdfMutation();
  const [taskFailed, setTaskFailed] = React.useState(false);
  const fileInput = useRef();
  const fileInput2 = useRef();
  const { resume, resumeTitle } = useSelector(
    (state) => state.project
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loaderMsg = 'Preparing your resume...';

  const { getAccessTokenSilently } = useAuth0();

  // handle submit for uploading file for the first time
  const handleUpload = (event) => {
    event.preventDefault();
    const file = fileInput.current.files[0];
    dispatch(projectSlice.actions.setResume(file));
  };

  // handle submit for uploading new file
  const handleUpload2 = (event) => {
    event.preventDefault();
    const file = fileInput2.current.files[0];
    dispatch(projectSlice.actions.setResume(file));
  };

  const resetForm = () => {
    dispatch(projectSlice.actions.setResume(null));
  };

  const cleanUpMemory = () => {
    dispatch(projectSlice.actions.setResume(null));
    localStorage.removeItem('resume');
    localStorage.removeItem('job');
  };

  const handleSubmit = () => {
    const pdf2Object = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        dispatch(authSlice.actions.setAccessToken(accessToken));

        let res = await uploadPdf(resume);

        setLoading(true);
        res = await fetchTask(res.data.task, accessToken);

        if (res.status === 'done') {
          dispatch(projectSlice.actions.setResumeObj(res.payload));
        } else {
          setTaskFailed(true);
        }
      } catch (error) {
        console.error(error.message);
        setTaskFailed(true);
      } finally {
        setLoading(false);
        cleanUpMemory();
        navigate('/project-view', {
          state: { from: location.pathname },
          replace: true,
        });
      }
    };

    pdf2Object();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    resetForm();
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    dispatch(projectSlice.actions.setResume(files[0]));
    setIsDragOver(false);
  };

  const firstName = user?.given_name || user?.name || 'User';
  return (
    <>
      {(taskFailed || error) && (
        <div className="text-red-500 text-center">
          {' '}
          Oops... An Error Occurred{' '}
        </div>
      )}
      {(loading || isLoading) && (
        <SpinnerLoader text={loaderMsg} withBox={true} />
      )}
      <Container
        location={location.pathname}
        className="relative flex flex-col justify-between items-center space-y-14 md:space-y-16 py-6 md:px-[80px] md:py-[80px] w-full md:w-[992px] bg-white dark:bg-black text-black dark:text-white xl:border-[#58718403] xl:border-[2px]"
      >
        <div className="flex flex-col w-full items-start space-y-3 px-6 md:px-0">
          <span className="text-[30px] md:text-[40px]">
            Welcome {firstName}!
          </span>
          <span>Just 4 steps. Upload your CV to get started...</span>
        </div>

        <StepTracker theme="blue" />

        <div className="flex flex-col w-full items-start justify-center px-6 md:p-0">
          <span className="mb-3">
            All documents uploaded must be in{' '}
            <span className="italic">.pdf</span> format
          </span>

          <form className="w-full">
            <div className="text-[14px] text-[#494B57] my-1">
              CV/Resume
            </div>
            {resume ? (
              <div
                className={`w-full flex flex-col md:flex-row justify-between items-center py-7 px-6 space-y-6 md:space-y-0 ${
                  isDragOver
                    ? 'border-[#219653] border-[4px] bg-[#E9F5E7]'
                    : ''
                } rounded-lg max-w-full border-[#DFE1E5] border-[1px] bg-[#E9F5E7]`}
              >
                <span className="text-[#219653] truncate w-full md:w-2/3 flex space-x-1 justify-start items-center">
                  <div className="w-[22px] h-[22px]">
                    <img
                      src="/images/icons/checkbox-green-light.svg"
                      alt="checkbox"
                      className="w-full h-full"
                    />
                  </div>
                  <span>{resume.name || resumeTitle}</span>
                </span>
                <div className="flex justify-start w-full md:justify-end space-x-2 items-center text-[14px]">
                  <label
                    htmlFor="resume"
                    className="text-[#219653] border-[#219653] rounded-[4px] bg-white border-[1px] w-[144px] h-[40px] flex justify-center items-center cursor-pointer"
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    {' '}
                    Upload new CV
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      accept="docx,doc,application/pdf"
                      className="hidden"
                      ref={fileInput}
                      onChange={handleUpload}
                    />
                  </label>
                  <button
                    onClick={resetForm}
                    className="text-brand-red bg-white border-[1px] rounded-[4px] border-brand-red w-[88px] h-[40px] flex justify-center items-center "
                  >
                    Reset
                  </button>
                </div>
              </div>
            ) : (
              <label
                htmlFor="resume"
                className="w-full cursor-pointer"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="w-full flex flex-col-reverse md:flex-row justify-between items-center py-7 px-6 border-[#DFE1E5] rounded-lg border-[1px] bg-transparent">
                  <span className="text-[#219653] text=[12px] md:hidden mt-2">
                    .pdf file
                  </span>
                  <span className="text-[#219653] hidden md:block">
                    Drag & drop a .pdf file
                  </span>
                  <div className="flex justify-between space-x-2 items-center text-[14px]">
                    <div className="text-[#219653] border-[#219653] rounded-[4px] bg-white border-[1px] w-[144px] h-[40px] flex justify-center items-center">
                      {' '}
                      Upload CV
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  accept="docx,doc,application/pdf"
                  className="hidden"
                  ref={fileInput2}
                  onChange={handleUpload2}
                />
              </label>
            )}
          </form>
        </div>

        <div className=" w-full text-[14px] flex flex-col items-center justify-center md:flex-row md:justify-end space-y-5 md:space-y-0 md:space-x-3 px-6 md:p-0">
          <button
            disabled={resume ? false : true}
            to={resume ? '/project-view' : '#'}
            className={`h-[42px] w-full md:w-[200px] flex justify-center items-center text-white bg-brand-cyan-dark rounded-[5px] ${
              !resume && 'opacity-50'
            }`}
            onClick={() => {
              handleSubmit();
            }}
          >
            Proceed with CV
          </button>
          <button
            onClick={() => {
              if (location.pathname === '/user-profile') {
                cleanUpMemory();
                closeModal();
              } else {
                cleanUpMemory();
                navigate('/user-profile', {
                  state: { from: location.pathname },
                  replace: true,
                });
              }
            }}
            className="h-[42px] w-full md:w-[200px] flex justify-center items-center text-[#828282] bg-transparent dark:text-white border-[1px] border-[#BDBDBD] rounded-[5px]"
          >
            Continue Later
          </button>
        </div>
      </Container>
    </>
  );
};

export default UploadResumeCard;
