import React from "react";
import { Minus } from "react-feather";

const StepTracker2 = ({ currentStep }) => {
  return (
    <div className="flex justify-center items-center">
      {[1, 2, 3].map((step, i) => (
        <React.Fragment key={i}>
          <div
            className={`flex justify-center items-center  rounded-full border min-w-[36px] min-h-[36px] w-[36px] h-[36px] md:w-[52px] md:h-[52px] ${
              step <= currentStep
                ? "text-brand-cyan-dark border-brand-cyan-dark"
                : "text-gray-300 border-gray-300"
            }`}
          >
            {step}
          </div>
          {i < 2 && (
            <div
              className={`mx-2 ${
                step <= currentStep
                  ? "text-brand-cyan-dark border-brand-cyan-dark"
                  : "text-gray-300 border-gray-300"
              }`}
            >
              <Minus />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepTracker2;
