import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
  const { logout } = useAuth0();
  return (
    <button
      onClick={() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
        localStorage.removeItem("access_token");
      }}
      className="w-full text-white h-11 md:w-44 border-[1px] border-[#F2F2F2] rounded-lg flex items-center justify-center text-xm font-medium  "
    >
      Logout
    </button>
  );
};

export default Logout;
