import React from 'react';
import { Account } from '../components/Account';
import DashboardHeader from '../components/common/DashboardHeader';
import usePortalSession from '../hooks/stripe/usePortalSession';
import ContactUs from '../components/common/ContactUs';

const MyAccount = () => {
  const { portalUrl } = usePortalSession;

  return (
    <div>
      <div className="bg-white dark:bg-black px-3 md:px-6 m-0">
        <DashboardHeader />
      </div>
      <div className="px-3 2xl:px-0">
        <Account portalURL={portalUrl} />
      </div>
      <div className="fixed right-0 bottom-0 m-8 ">
        <ContactUs />
      </div>
    </div>
  );
};

export default MyAccount;
