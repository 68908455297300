import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectSlice } from '../../store';
import { useScreenWidth } from '../../hooks';
import { FixResumeBtn, ExportCvBtn, UndoChangesBtn } from './Buttons';
import { StepTracker2 } from './StepTracker';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useUpdateResumeMutation } from '../../store/apis/projectApi';
import ResumeTemplates from './Cards/ResumeTemplates';
import { SpinnerLoader } from './Loader';
import { ErrorToast } from './Toast';
import Portal from './Portal';
import ModalWrapper from './StyledComponents/ModalWrapper.styled';
import FeedbackForm from './Cards/FeedbackForm';
import { DownloadSuccessfulCard } from './Cards';
import { modalSlice } from '../../store';
import { toast } from 'react-toastify';

const Insights = ({ data }) => {
  const dispatch = useDispatch();
  const insights = useSelector((state) => state.project.insights);
  const { resumes } = useSelector((state) => state.project);
  const page = useSelector((state) => state.project.page);
  const continueWithoutFixing = useSelector(
    (state) => state.project.continueWithoutFixing
  );
  const { resumeId, enhancedResume } = useSelector(
    (state) => state.project
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { downloadSuccessful } = useSelector((state) => state.modal);
  const [fixResume, setFixResume] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [showAllInsights, setShowAllInsights] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateResume] = useUpdateResumeMutation();

  const resumeCount = resumes.length;

  useEffect(() => {
    if (fixResume || continueWithoutFixing) {
      dispatch(projectSlice.actions.setPage(3));
    }
  }, [fixResume, continueWithoutFixing, dispatch]);

  useEffect(() => {
    setAllSelected(insights.every((insight) => insight.selected));
  }, [insights]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCheckboxToggle = (index) => {
    const updatedInsights = insights.map((insight, idx) =>
      idx !== index
        ? insight
        : { ...insight, selected: !insight.selected }
    );
    setAllSelected(
      updatedInsights.every((insight) => insight.selected)
    );
    dispatch(projectSlice.actions.setInsights(updatedInsights));
  };

  const handleSelectAll = () => {
    const updatedInsights = insights.map((insight) => ({
      ...insight,
      selected: !allSelected,
    }));
    setAllSelected(!allSelected);
    dispatch(projectSlice.actions.setInsights(updatedInsights));
  };

  const renderButtons = () => {
    if (page === 2) {
      return (
        <div className="flex flex-col space-y-3">
          <FixResumeBtn data={data} setFixResume={setFixResume} />
          <button
            onClick={() => exportCV()}
            className="text-[14px] flex justify-center items-center py-3 px-5 w-full space-x-3 hover:bg-gray-50"
          >
            <div>
              <img
                src="/images/icons/export.png"
                alt="download icon"
              />
            </div>
            <span className="underline text-[#333333] font-medium">
              Export Without Fixes
            </span>
          </button>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center space-y-3 mb-10 lg:mb-0 h-full justify-center ">
        <span className="text-[16px] text-[#727272] mb-6 text-center">
          Fantastic work! <br></br>Would you like to export the final
          results?
        </span>
        <ExportCvBtn data={data} />
      </div>
    );
  };

  const renderInsightList = () => {
    if (continueWithoutFixing) return null;

    return (
      <div
        className={`${!showInsights && 'hidden'} lg:flex flex-col w-full`}
      >
        <SelectAll
          handleSelectAll={handleSelectAll}
          allSelected={allSelected}
          insightsCount={insights.length}
        />
        {showAllInsights
          ? insights.map((insight, i) => (
              <Insight
                key={i}
                insight={insight}
                handleCheckboxToggle={() => handleCheckboxToggle(i)}
              />
            ))
          : insights
              .slice(0, 4)
              .map((insight, i) => (
                <Insight
                  key={i}
                  insight={insight}
                  handleCheckboxToggle={() => handleCheckboxToggle(i)}
                />
              ))}
        <button
          onClick={() => setShowAllInsights(!showAllInsights)}
          className="flex w-full text-brand-cyan-dark underline mt-2"
        >
          {!showAllInsights ? (
            <div className="flex items-center space-x-3 text-[14px]">
              <div>See {insights.length - 4} more suggestions</div>
              <ChevronDown className="w-5 h-5" />
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              <div>Show less</div>
              <ChevronUp className="w-5 h-5" />
            </div>
          )}
        </button>
      </div>
    );
  };

  const exportCV = async () => {
    // update resume
    const jsonData = JSON.stringify(data);
    const updatedResume = {
      id: data.id,
      resume: { content: jsonData, title: data.title },
    };

    dispatch(projectSlice.actions.setEnhancedResume(data));
    dispatch(projectSlice.actions.setActiveResume(data));
    try {
      setIsLoading(true);
      await updateResume(updatedResume);

      setModalIsOpen(true);
    } catch (error) {
      setError({ message: 'Failed to update resume' });
      toast.error('Failed to update resume');
      console.error('Error updating resume:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoader text="Saving your resume..." />}
      {error && <ErrorToast text={error} />}
      <div className="h-full flex flex-col min-w-[320px] ">
        {page === 3 ? (
          <div className="flex-1 flex flex-col p-2 lg:p-0 lg:justify-center space-y-6">
            <StepTracker2 currentStep={page} />
            <div className="w-full flex-col justify-center items-center space-y-3">
              {renderButtons()}
            </div>
          </div>
        ) : (
          <div className="flex-1 flex flex-col p-2 lg:p-0">
            <div className="mb-4">
              <StepTracker2 currentStep={page} />
            </div>
            {!fixResume && (
              <div className="w-full mb-4">
                <InsightsTitle
                  showInsights={showInsights}
                  setShowInsights={setShowInsights}
                />
              </div>
            )}

            {fixResume && (
              <div className="hidden lg:block mb-4">
                <UndoChangesBtn setFixResume={setFixResume} />
              </div>
            )}

            <div className="flex-1 min-h-0 overflow-y-auto">
              {renderInsightList()}
            </div>

            <div className="mt-4 pb-10 lg:pb-12">
              {renderButtons()}
            </div>
          </div>
        )}
      </div>

      {modalIsOpen && (
        <Portal>
          <ModalWrapper>
            <ResumeTemplates
              resumeId={resumeId}
              closeModal={closeModal}
              modalIsOpen={modalIsOpen}
            />
          </ModalWrapper>
        </Portal>
      )}
      {downloadSuccessful && (
        <Portal>
          <ModalWrapper>
            {/* @Todo: Use Resume length to show feedback form */}
            {resumeCount < 2 ? (
              <FeedbackForm
                closeModal={() =>
                  dispatch(
                    modalSlice.actions.setDownloadSuccessful(false)
                  )
                }
              />
            ) : (
              <DownloadSuccessfulCard />
            )}
          </ModalWrapper>
        </Portal>
      )}
    </>
  );
};

const Insight = ({ insight, handleCheckboxToggle }) => (
  <div className="w-full mb-4">
    <button
      onClick={handleCheckboxToggle}
      className="flex justify-start items-start space-x-2 w-full text-left"
    >
      <div className="min-w-[20px] min-h-[20px] w-[20px] h-[20px]">
        <img
          src={`/images/icons/checkbox${
            insight.selected ? '-checked' : ''
          }.svg`}
          alt={insight.selected ? 'checked icon' : 'unchecked icon'}
          className="w-full h-full"
        />
      </div>
      <div className="flex flex-col w-full justify-start items-start">
        <span
          className={`italic text-[12px] mb-1 opacity-60 text-[#312E2E] ${
            insight.selected && 'line-through'
          }`}
        >
          {insight.insight}
        </span>
        <hr className="w-[80%] fill-red-600 my-2" />
      </div>
    </button>
  </div>
);

const SelectAll = ({
  handleSelectAll,
  allSelected,
  insightsCount,
}) => (
  <button
    onClick={handleSelectAll}
    className="flex justify-start items-start space-x-2 w-full text-left font-semibold"
  >
    <div className="min-w-[20px] min-h-[20px] w-[20px] h-[20px]">
      <img
        src={`/images/icons/checkbox${
          allSelected ? '-checked' : ''
        }.svg`}
        alt={allSelected ? 'checked icon' : 'unchecked icon'}
        className="w-full h-full"
      />
    </div>
    <div className="flex flex-col w-full justify-start items-start">
      <span
        className={`italic text-[14px] mb-1 opacity-80 text-[#312E2E] ${
          allSelected && 'line-through'
        }`}
      >
        Select all {insightsCount} suggestions
      </span>
    </div>
  </button>
);

const InsightsTitle = ({ setShowInsights, showInsights }) => {
  const width = useScreenWidth();

  useEffect(() => {
    if (width > 1023) setShowInsights(true);
  }, [width, setShowInsights]);

  return (
    <button
      disabled={width > 1023}
      onClick={() => setShowInsights(!showInsights)}
      className="bg-[#E0FFE3] w-full flex justify-start items-stretch py-2 lg:py-2 px-4 rounded border-[#28D32F] border-[0.5px]"
    >
      <div className="flex-shrink-0 pt-1">
        <img
          className="w-[24px] h-[24px]"
          src="/images/icons/bulb-green.svg"
          alt="bulb icon"
        />
      </div>
      <div className="flex flex-1 ml-3">
        <div className="flex-1 text-left text-[14px] text-brand-green">
          <span className="text-brand-green">
            Select the fixes you would like to apply <br />
            <span className="lg:hidden">
              {showInsights ? 'Click to Hide' : 'Click to Show'}{' '}
              insights
            </span>
          </span>
        </div>
        <div className="lg:hidden flex items-center ml-2 pl-2">
          {showInsights ? (
            <ChevronUp className="w-6 h-6 text-brand-green stroke-[2.5]" />
          ) : (
            <ChevronDown className="w-6 h-6 text-brand-green stroke-[2.5]" />
          )}
        </div>
      </div>
    </button>
  );
};

export default Insights;
