import React from "react";
import { Link } from "react-router-dom";

const RoundedButtonWithIcon = ({ text, icon }) => {
  return (
    <Link
      to="/register"
      className="text-white text-xl md:text-xl w-64 h-14 md:w-56 md:h-12 flex space-x-2 justify-center items-center rounded-lg px-12 bg bg-gradient-to-r from-[#9494E5] to-[#00B3FF]"
    >
      {text}
      {icon}
    </Link>
  );
};

export default RoundedButtonWithIcon;
