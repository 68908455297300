import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextInput from './TextInput';
import { Plus } from 'react-feather';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { DeleteButton } from './ResumeForm';
import { ConfirmDeleteDialogue } from '../../Dialogue';
import { toast } from 'react-toastify';

const Skills = React.memo(({ data, setData, deleteIconClass }) => {
  const [newKeywords, setNewKeywords] = useState({});
  const [showDeleteSkillDialog, setShowDeleteSkillDialog] =
    useState(false);
  const [showDeleteKeywordDialog, setShowDeleteKeywordDialog] =
    useState(false);
  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedKeywordInfo, setSelectedKeywordInfo] =
    useState(null);

  const skills = data.skills || [];

  // Assign unique IDs to skills that lack them
  useEffect(() => {
    const updatedSkills = skills.map((skill) => {
      if (!skill.id) {
        const newId = uuidv4();
        return { ...skill, id: newId };
      }
      return skill;
    });

    const hasMissingIds = updatedSkills.some(
      (skill, index) => !skills[index].id
    );

    if (hasMissingIds) {
      setData((prevData) => ({ ...prevData, skills: updatedSkills }));
    }
  }, [skills, setData]);

  // Handle changes to the skill name
  const handleSkillNameChange = useCallback(
    (e, skillId) => {
      const { value } = e.target;
      setData((prevData) => ({
        ...prevData,
        skills: prevData.skills.map((skill) =>
          skill.id === skillId ? { ...skill, name: value } : skill
        ),
      }));
    },
    [setData]
  );

  // Handle changes to a specific keyword
  const handleKeywordChange = useCallback(
    (e, skillId, keywordIndex) => {
      const { value } = e.target;

      setData((prevData) => ({
        ...prevData,
        skills: prevData.skills.map((skill) => {
          if (skill.id === skillId) {
            const updatedKeywords = [...(skill.keywords || [])];
            updatedKeywords[keywordIndex] = value;
            return { ...skill, keywords: updatedKeywords };
          }
          return skill;
        }),
      }));
    },
    [setData]
  );

  // Handle deletion of a skill
  const handleDeleteSkill = useCallback((e, skillId) => {
    e.preventDefault(); // Prevent form submission
    setSelectedSkillId(skillId);
    setShowDeleteSkillDialog(true);
  }, []);

  // Confirm deletion of a skill
  const confirmDeleteSkill = useCallback(() => {
    if (selectedSkillId) {
      setData((prevData) => ({
        ...prevData,
        skills: prevData.skills.filter(
          (skill) => skill.id !== selectedSkillId
        ),
      }));
      setShowDeleteSkillDialog(false);
      toast.success('Skill deleted successfully');
    }
  }, [selectedSkillId, setData]);

  // Handle deletion of a keyword
  const handleDeleteKeyword = useCallback(
    (e, skillId, keywordIndex) => {
      e.preventDefault(); // Prevent form submission
      setSelectedKeywordInfo({ skillId, keywordIndex });
      setShowDeleteKeywordDialog(true);
    },
    []
  );

  // Confirm deletion of a keyword
  const confirmDeleteKeyword = useCallback(() => {
    if (selectedKeywordInfo) {
      const { skillId, keywordIndex } = selectedKeywordInfo;
      setData((prevData) => ({
        ...prevData,
        skills: prevData.skills.map((skill) => {
          if (skill.id === skillId) {
            const updatedKeywords = skill.keywords.filter(
              (_, i) => i !== keywordIndex
            );
            return { ...skill, keywords: updatedKeywords };
          }
          return skill;
        }),
      }));
      setShowDeleteKeywordDialog(false);
      toast.success('Keyword deleted successfully');
    }
  }, [selectedKeywordInfo, setData]);

  // Handle adding a new skill
  const handleAddSkill = useCallback(() => {
    const newId = uuidv4();
    console.log('Adding new skill with ID:', newId);
    const newSkill = {
      id: newId,
      name: '',
      level: '',
      keywords: [],
    };
    setData((prevData) => ({
      ...prevData,
      skills: [...(prevData.skills || []), newSkill],
    }));
  }, [setData]);

  // Handle adding a new keyword to a specific skill
  const handleAddKeyword = useCallback(
    (skillId) => {
      const newKeyword = newKeywords[skillId]?.trim();
      if (newKeyword) {
        setData((prevData) => ({
          ...prevData,
          skills: prevData.skills.map((skill) =>
            skill.id === skillId
              ? {
                  ...skill,
                  keywords: [...(skill.keywords || []), newKeyword],
                }
              : skill
          ),
        }));
        setNewKeywords((prev) => ({ ...prev, [skillId]: '' }));
      }
    },
    [newKeywords, setData]
  );

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-[24px] md:text-[28px]">Skills</div>
      <div className="flex flex-col space-y-4 w-full">
        {skills.map((skill) => (
          <div className="w-full" key={skill.id}>
            <div className="flex justify-between items-center w-full mb-2">
              <TextInput
                label=""
                type="text"
                name="name"
                value={skill.name}
                handleChange={(e) =>
                  handleSkillNameChange(e, skill.id)
                }
                className="w-full font-bold text-[18px]"
                placeholder="Skill Name"
              />
              <DeleteButton
                onClick={(e) => handleDeleteSkill(e, skill.id)}
              >
                <XCircleIcon className="h-5 w-5" />
              </DeleteButton>
            </div>
            <div className="ml-4 md:ml-6">
              {skill.keywords &&
                skill.keywords.map((keyword, keywordIndex) => (
                  <div
                    key={`${skill.id}-${keywordIndex}`}
                    className="flex items-center space-x-2 mb-2"
                  >
                    <TextInput
                      label=""
                      type="text"
                      name="keyword"
                      value={keyword}
                      handleChange={(e) =>
                        handleKeywordChange(e, skill.id, keywordIndex)
                      }
                      className="text-[14px] w-full"
                      placeholder="Keyword"
                    />
                    <DeleteButton
                      onClick={(e) =>
                        handleDeleteKeyword(e, skill.id, keywordIndex)
                      }
                    >
                      <XCircleIcon className="h-5 w-5" />
                    </DeleteButton>
                  </div>
                ))}
              <div className="flex items-center space-x-2 mt-2">
                <TextInput
                  label=""
                  type="text"
                  name="newKeyword"
                  value={newKeywords[skill.id] || ''}
                  handleChange={(e) =>
                    setNewKeywords((prev) => ({
                      ...prev,
                      [skill.id]: e.target.value,
                    }))
                  }
                  className="text-[14px] w-full"
                  placeholder="Add new keyword"
                />
                <button
                  type="button"
                  onClick={() => handleAddKeyword(skill.id)}
                  className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center text-[12px] px-2 py-2"
                >
                  Add Keyword
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddSkill}
          className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add Skill
        </button>
      </div>

      {/* Delete Skill Confirmation Dialog */}
      {showDeleteSkillDialog && (
        <ConfirmDeleteDialogue
          title="Remove skill?"
          description="Are you sure you want to delete this skill? You will lose all data related to this skill."
          cancel={() => setShowDeleteSkillDialog(false)}
          handleDelete={confirmDeleteSkill}
        />
      )}

      {/* Delete Keyword Confirmation Dialog */}
      {showDeleteKeywordDialog && (
        <ConfirmDeleteDialogue
          title="Remove keyword?"
          description="Are you sure you want to delete this keyword?"
          cancel={() => setShowDeleteKeywordDialog(false)}
          handleDelete={confirmDeleteKeyword}
        />
      )}
    </div>
  );
});

export default Skills;
