const socialLinks = [
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/revats-ai",
  //   icon: "images/icons/twitter.svg",
  // },
  // {
  //   name: "Instagram",
  //   link: "https://instagram.com/revats-ai",
  //   icon: "images/icons/instagram.svg",
  // },
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/company/revats-ai",
    icon: "images/icons/linkedin.svg",
  }
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/revatsio",
  //   icon: "images/icons/youtube.svg",
  // },
];

export default socialLinks;
