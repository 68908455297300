import React, { useState } from 'react';

const RenameResumeDialog = ({ cancel, handleRenameResume }) => {
  const [newTitle, setNewTitle] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRenameResume({ newTitle });
  };

  return (
    <div className="bg-white p-6 rounded-lg w-80vw md:max-w-[464px] relative">
      <button
        onClick={cancel}
        className=" flex justify-self-end items-center p-1 hover:bg-gray-100 rounded-full mb-2"
        aria-label="Close dialog"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18M6 6L18 18"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <div className="text-xl font-semibold text-black text-left mb-2">
        <h3 className="text-[#575757] text-lg font-normal">
          Give this resume a name:
        </h3>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <input
          type="text"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          placeholder="My Senior Engineer Resume"
          className="w-full px-4 py-2 border rounded-sm focus:outline-none focus:ring-1 focus:ring-[#58BCF5] placeholder:text-[#000] placeholder:opacity-10 h-[44px]  "
          autoFocus
        />

        <div className="flex justify-end items-center space-x-4 mt-4">
          <button
            type="submit"
            className="flex justify-center space-x-2 items-center text-white bg-[#58BCF5] px-5 py-3 rounded-sm  w-full h-[40px]"
          >
            <span>Save</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default RenameResumeDialog;
