import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    setMounted(true);
    document.body.appendChild(el);
    return () => document.body.removeChild(el);
  }, [el]);

  if (!mounted) {
    return null;
  }

  return createPortal(children, el);
};

export default Portal;
