import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import resumePdf2Base64String from "../../../utils/resumePdf2Base64String";

const LaunchAppButton = () => {
  const { resume, job } = useSelector((state) => state.project);

  const navigate = useNavigate();

  const onClick = () => {
    resumePdf2Base64String(resume);
    localStorage.setItem("job", job);
    localStorage.setItem("resumeTitle", resume.name);

    navigate("/register");
  };

  return (
    <button
      onClick={onClick}
      className="heart-beat-button-purple text-brand-dark font-medium  text-[12px] 2xl:text-base w-36 h-10 lg:w-28 lg:h-8 2xl:w-36 2xl:h-10 flex space-x-2 justify-center items-center bg-[#B78CE4] rounded-lg"
    >
      <p>Launch App</p>
      <img src="/images/icons/rocket-up.svg" alt="arrow" />
    </button>
  );
};

export default LaunchAppButton;
