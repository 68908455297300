import React, { useState } from 'react';
import { ResumeLayout } from '../Layouts';
import {
  HorizontalResumeTab,
  VerticalResumeTab,
} from '../common/Tabs';
import ResumeForm from '../common/Forms/ResumeForm';
import Insights from '../common/Insights';
import { useSelector } from 'react-redux';
import resumeTabs from '../../constants/resumeTabs';

const CompareResume = ({ resume }) => {
  const [data, setData] = useState({
    ...resume.content,
    title: resume?.title,
    id: resume?.id,
  });

  const [selectedTab, setSelectedTab] = useState('section1');
  const [shouldScroll, setShouldScroll] = useState(false);

  const handleTabClick = (ref) => {
    setSelectedTab(ref);
    setShouldScroll(true);
  };

  return (
    <div className="w-full">
      <ResumeLayout
        VerticalResumeTab={
          <VerticalResumeTab
            tabs={resumeTabs}
            selectedTab={selectedTab}
            handleTabClick={handleTabClick}
          />
        }
        HorizontalResumeTab={
          <HorizontalResumeTab
            tabs={resumeTabs}
            selectedTab={selectedTab}
            handleTabClick={handleTabClick}
          />
        }
        ResumeForm={
          <ResumeForm
            selectedTab={selectedTab}
            data={data}
            setData={setData}
            shouldScroll={shouldScroll}
            setShouldScroll={setShouldScroll}
          />
        }
        NextStep={<Insights data={data} />}
      />
    </div>
  );
};

export default CompareResume;
