import React from 'react';
import { UpgradeSuccessfulCard } from '../components/common/Cards';
import DashboardHeader from '../components/common/DashboardHeader';
import Footer from '../components/common/Footer';
import ContactUs from '../components/common/ContactUs';

const Success = () => {
  return (
    <>
      <DashboardHeader />
      <div className="flex items-center justify-center w-full">
        <UpgradeSuccessfulCard />{' '}
      </div>
      <Footer />
      <div className="fixed right-0 bottom-0 m-8 ">
        <ContactUs />
      </div>
    </>
  );
};

export default Success;
