import React from "react";
import Socials from "./Socials";
import Logo from "./Logo";
import Copyright from "./copyright";

const Footer = () => {
  return (
    <div className="bg-brand-dark py-10 px-3 flex justify-center items-center  w-full">
      <div className="container flex flex-col md:flex-row justify-between items-start md:items-center w-full">
        <div className="flex px-0 md:px-0 my-5 md:mb-0 mx-auto">
          <Socials />
        </div>
        <div className="flex mx-auto order-[-1] md:order-1 mb-2.5 md:mb-0 ">
          <Logo theme="light" />
        </div>
        <div className="flex mx-auto  order-3  md:px-0 mb-10 md:mb-0">
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default Footer;
