import React from "react";
import { CreateNewApplication } from "./Buttons";

const NoProjectCreated = () => {
  return (
    <div className="flex flex-col justify-center items-center space-y-4 text-black dark:text-white">
      <div className="w-[62px] h-[62px] md:w-[72px] md:h-[72px]">
        <img
          src="/images/icons/rocket-blue.svg"
          alt="Rocket"
          className="w-full h-full"
        />
      </div>
      <span className="text-[24px] md:text-[32px] font-medium">
        No Project Created
      </span>
      <span className="text-[14px] md:text-[16px]">
        Click the button bellow to start creating
      </span>
      <CreateNewApplication text="Start Project" textOnMobile={true} />
    </div>
  );
};

export default NoProjectCreated;
