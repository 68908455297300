import React from 'react';
import { Resumes } from '../components/Dashboard';
import DashboardHeader from '../components/common/DashboardHeader';
import ContactUs from '../components/common/ContactUs';

const MyResumes = () => {
  return (
    <div className="md:h-screen md:overflow-hidden bg-white dark:bg-black px-3 md:px-6 m-0">
      <DashboardHeader />
      <Resumes />
      <div className="flex-grow"></div>

      <div className="fixed right-0 bottom-0 m-8 ">
        <ContactUs />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default MyResumes;
