function formatDate(inputDate) {
  const date = new Date(inputDate);
  if (!inputDate) return {};
  // Ensure the date is valid
  if (isNaN(date)) {
    throw new Error("Invalid date");
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Get ordinal suffix for the day
  let ordinalSuffix;
  if (day % 10 === 1 && day !== 11) {
    ordinalSuffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    ordinalSuffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    ordinalSuffix = "rd";
  } else {
    ordinalSuffix = "th";
  }

  // Get month name
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[monthIndex];

  // Construct formatted date string
  const formattedDate = `${day}${ordinalSuffix} ${monthName}, ${year}`;

  return formattedDate;
}

export default formatDate;
