import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1000; /* Ensures the modal is on top */

  /*Responsive Modal Container */
  > div {
    background: white;
    border-radius: 8px;
    width: 100%;
    max-height: 90%;
    overflow-y: hidden;
    padding: 20px;
    position: relative;

    @media (min-width: 768px) {
      width: 80%;
    }

    @media (min-width: 1024px) {
      width: 60%;
    }
  }
`;

export default ModalWrapper;
