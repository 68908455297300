import React from "react";
import UpgradeFailedCard from "../components/common/Cards/UpgradeFailedCard";
import DashboardHeader from "../components/common/DashboardHeader";
import Footer from "../components/common/Footer";
import ContactUs from "../components/common/ContactUs";

const Cancel = () => {
  return (
    <>
      <DashboardHeader />
      <div className="flex items-center justify-center  w-full">
        <UpgradeFailedCard />{" "}
      </div>
      <div>
        <ContactUs />
      </div>
      <Footer />
    </>
  );
};

export default Cancel;
