import axios from "axios";
import { baseUrl } from "../store/apis/config";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const fetchTask = async (task_id, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = await axios.get(`${baseUrl}/get_task/${task_id}`, config);
  while (res.data.status === "in_progress") {
    await delay(2000);
    res = await axios.get(`${baseUrl}/get_task/${task_id}`, config);
  }
  return res.data;
};
