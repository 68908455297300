import { CompareResume } from '../../components/Dashboard';

import { useSelector } from 'react-redux';

const CompareResumePage = () => {
  const { activeResume } = useSelector((state) => state.project);

  // Create a new object with parsed content if necessary
  const resume = {
    ...activeResume,
    content:
      typeof activeResume.content === 'string'
        ? JSON.parse(activeResume.content)
        : activeResume,
  };

  return <CompareResume resume={resume} />;
};

export default CompareResumePage;
