import React from 'react';

const DateInput = ({ type, label, name, value, handleChange }) => {
  return (
    <label
      htmlFor={name}
      className={`text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2 w-full mt-3 ${
        type === 'textArea' && 'mt-4'
      }`}
    >
      <span>{label}</span>
      <input
        value={value}
        id={name}
        name={name}
        type={type}
        className="w-full rounded-[8px] border-[1px] border-[#D3D5DB] py-3 px-4 focus:outline-none focus:ring-1 focus:ring-brand-cyan-dark focus:border-transparent"
        onChange={handleChange}
      ></input>
    </label>
  );
};

export default DateInput;
