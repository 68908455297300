import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ theme = "light", url = "/" }) => {
  return (
    <Link to={url}>
      <img
        src={`/images/logos/${
          theme === "dark" ? "revats-dark" : "revats-light"
        }.svg`}
        alt="logo"
        className="md:w-full"
      />
    </Link>
  );
};

export default Logo;
