import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { baseUrl } from './config';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    uploadPdf: builder.mutation({
      query: (resume) => {
        let formData = new FormData();
        formData.append('upload_file', resume);
        return {
          url: '/uploadpdf',
          method: 'POST',
          body: formData,
        };
      },
    }),
    createResume: builder.mutation({
      query: (data) => {
        return {
          url: '/resumes',
          method: 'POST',
          body: {
            content: data,
          },
        };
      },
    }),
    updateResume: builder.mutation({
      query: ({ id, resume }) => {
        return {
          url: `/resumes/${id}`,
          method: 'PATCH',
          body: {
            content: resume.content,
            title: resume.title,
          },
        };
      },
    }),
    getScore: builder.mutation({
      query: (data) => {
        return {
          url: '/get_score',
          method: 'POST',
          body: data,
        };
      },
    }),
    enhanceResume: builder.mutation({
      query: (data) => {
        return {
          url: '/enhance_cv',
          method: 'POST',
          body: data,
        };
      },
    }),
    exportResume: builder.mutation({
      query: (data) => {
        return {
          url: `/resumes/${data.resume_id}/downloadurl`,
          method: 'POST',
          body: data.metadata,
        };
      },
    }),
    deleteResume: builder.mutation({
      query: (id) => {
        return {
          url: `/resumes/${id}`,
          method: 'DELETE',
        };
      },
    }),
    setDefaultResume: builder.mutation({
      query: (id) => {
        return {
          url: `/user/set_default_resume`,
          method: 'POST',
          body: {
            resume_id: id,
          },
        };
      },
    }),
    resumes: builder.mutation({
      query: () => {
        return {
          url: '/user',
          method: 'GET',
        };
      },
    }),
    getResumePreview: builder.mutation({
      query: ({ resume_id, template }) => ({
        url: `/resumes/${resume_id}/preview`,
        method: 'POST',
        body: { template },
      }),
    }),
  }),
});

export const {
  useResumesMutation,
  useUpdateResumeMutation,
  useUploadPdfMutation,
  useCreateResumeMutation,
  useGetScoreMutation,
  useEnhanceResumeMutation,
  useExportResumeMutation,
  useDeleteResumeMutation,
  useSetDefaultResumeMutation,
  useGetResumePreviewMutation,
} = projectApi;
