import React, { useState, useEffect } from 'react';
import { SpinnerLoader } from '../components/common/Loader';
import usePortalSession from '../hooks/stripe/usePortalSession';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import stripe from '../libs/stripe';

export default function Subscription() {
  const [sessionID, setSessionID] = useState('');
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();
  const portalURL = usePortalSession();

  useEffect(() => {
    const sessionSetter = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        if (!sessionID && accessToken) {
          const session =
            await stripe.createCheckoutSession(accessToken);
          if (session && session.sessionId) {
            setSessionID(session.sessionId);
            setLoading(false);
          } else if (session.error) {
            throw new Error(session.error);
          }
        }
      } catch (error) {
        console.error('Error creating checkout session', error);
        setErrorMessage(
          'Failed to create session. Please try again later.'
        );
        setLoading(false);
      }
    };

    if (!sessionID) {
      sessionSetter();
    }
  }, [sessionID]);

  // New useEffect to handle redirection logic
  useEffect(() => {
    if (sessionID) {
      if (portalURL.url) {
        window.location.href = portalURL.url;
      } else {
        stripe.redirectToCheckout(sessionID);
      }
    }
  }, [sessionID, portalURL, navigate]);

  if (loading) {
    return <SpinnerLoader />;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return null;
}
