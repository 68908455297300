import { Home } from "../components/Home";
import DarkModeToggle from "../components/common/DarkModeToggle";
import { useAuth0 } from "@auth0/auth0-react";
import ContactUs from "../components/common/ContactUs";

export default function HomePage() {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="h-full bg-dark-gradient">
      {/* <DarkModeToggle /> */}
      <div className="">
        <Home isAuthenticated={isAuthenticated} />
      </div>
      <div className="fixed right-0 bottom-0 m-8 ">
        <ContactUs />
      </div>
    </div>
  );
}
