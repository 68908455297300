import React from 'react';
import ResumeCardSmall from './Cards/ResumeCardSmall';
import { UploadNewResumeBtnPlain } from './Buttons/UploadNewResumeBtn';
import UploadNewResumeBtn from './Buttons/UploadNewResumeBtn';
import { useSelector } from 'react-redux';
import formatDate from '../../utils/formatDate';

const FeaturedResumes = () => {
  const { activeResume, resumes } = useSelector(
    (state) => state.project
  );

  // Separate the active resume and other resumes
  const activeResumeObj = resumes.find(
    (resume) => resume.id === activeResume?.id
  );
  const otherResumes = resumes.filter(
    (resume) => resume.id !== activeResume?.id
  );

  const sortedResumes = activeResumeObj
    ? [activeResumeObj, ...otherResumes]
    : otherResumes;

  return (
    <div className="w-full relative h-full flex flex-col">
      <div className="flex justify-between items-center xlg:mb-4 mb-2">
        <h2 className="text-lg lg:text-xl font-medium">
          Your Resumes
        </h2>
        <div className="xl:hidden">
          <UploadNewResumeBtnPlain />
        </div>
      </div>
      <div className="flex flex-col gap-4 xl:h-[calc(100vh-280px)] overflow-y-auto md:px-2">
        <div className="relative flex xl:grid xl:grid-cols-1 overflow-x-auto xl:overflow-visible gap-4">
          {sortedResumes?.map((resume) => (
            <div
              key={resume.id}
              className="w-[300px]  xl:w-full flex-shrink-0"
            >
              <ResumeCardSmall
                title={resume.title}
                lastUpdated={formatDate(resume.last_updated)}
                content={resume.content}
                id={resume.id}
              />
            </div>
          ))}
        </div>
        <div className="relative bg-white pt-2 pb-12 hidden xl:block mb-8">
          <UploadNewResumeBtn />
        </div>
      </div>
    </div>
  );
};

export default FeaturedResumes;
