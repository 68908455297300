import React, { useState } from 'react';
import { AlertCircle, CreditCard, X } from 'lucide-react';

// Button component
const Button = React.forwardRef(
  ({ className, variant, size, ...props }, ref) => {
    const baseStyles =
      'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background';
    const variants = {
      default:
        'bg-primary text-primary-foreground hover:bg-primary/90',
      destructive:
        'bg-destructive text-destructive-foreground hover:bg-destructive/90',
      outline:
        'border border-input hover:bg-accent hover:text-accent-foreground',
    };
    const sizes = {
      default: 'h-10 py-2 px-4',
      sm: 'h-9 px-3 rounded-md',
      lg: 'h-11 px-8 rounded-md',
    };
    return (
      <button
        className={`${baseStyles} ${variants[variant] || variants.default} ${sizes[size] || sizes.default} ${className}`}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

// Card components
const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}
    {...props}
  />
));
Card.displayName = 'Card';

const CardHeader = React.forwardRef(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={`flex flex-col space-y-1.5 p-6 ${className}`}
      {...props}
    />
  )
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={`text-2xl font-semibold leading-none tracking-tight ${className}`}
    {...props}
  />
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={`text-sm text-muted-foreground ${className}`}
      {...props}
    />
  )
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={`p-6 pt-0 ${className}`} {...props} />
  )
);
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={`flex items-center p-6 pt-0 ${className}`}
      {...props}
    />
  )
);
CardFooter.displayName = 'CardFooter';

// Main component
export default function CancelMembershipCard() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const handleCancel = () => {
    setShowConfirmation(true);
  };

  const confirmCancel = () => {
    // Here you would typically make an API call to cancel the membership
    setIsCancelled(true);
    setShowConfirmation(false);
  };

  if (isCancelled) {
    return (
      <Card className="w-full max-w-md mx-auto bg-gradient-to-br from-gray-50 to-gray-100 shadow-lg">
        <CardHeader className="text-center">
          <CardTitle className="text-2xl font-light">
            Membership Cancelled
          </CardTitle>
          <CardDescription className="text-gray-500">
            Your membership has been successfully cancelled.
          </CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center">
          <X className="h-16 w-16 text-red-500" />
        </CardContent>
        <CardFooter className="text-center">
          <p className="text-sm text-gray-500">
            We're sorry to see you go. You can reactivate your
            membership at any time by logging into your account.
          </p>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-md mx-auto bg-gradient-to-br from-gray-50 to-gray-100 shadow-lg">
      <CardHeader>
        <CardTitle className="text-2xl font-light">
          Cancel Membership
        </CardTitle>
        <CardDescription className="text-gray-500">
          We're sad to see you go. Are you sure you want to cancel
          your plan?
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center space-x-4">
            <CreditCard className="h-8 w-8 text-blue-500" />
            <div>
              <h3 className="font-semibold text-lg">
                Current Plan: Premium
              </h3>
              <p className="text-sm text-gray-500">
                Active until: July 31, 2023
              </p>
            </div>
          </div>
        </div>
        {showConfirmation && (
          <div className="bg-red-50 text-red-600 p-6 rounded-lg flex items-start space-x-4 animate-in slide-in-from-top duration-300">
            <AlertCircle className="h-6 w-6 mt-0.5 flex-shrink-0" />
            <div>
              <h4 className="font-semibold text-lg">Are you sure?</h4>
              <p className="text-sm">
                This action cannot be undone. You will lose access to
                all premium features.
              </p>
            </div>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex justify-end space-x-4">
        {showConfirmation ? (
          <>
            <Button
              variant="outline"
              onClick={() => setShowConfirmation(false)}
              className="transition-colors hover:bg-gray-100"
            >
              Go Back
            </Button>
            <Button
              variant="destructive"
              onClick={confirmCancel}
              className="bg-red-500 hover:bg-red-600 transition-colors"
            >
              Confirm Cancellation
            </Button>
          </>
        ) : (
          <Button
            variant="destructive"
            onClick={handleCancel}
            className="bg-red-500 hover:bg-red-600 transition-colors"
          >
            Cancel Membership
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}
