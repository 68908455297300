import React, { useState } from 'react';
import styled from 'styled-components';
import ProjectTitle from '../Dashboard/ProjectTitle';
import { useSelector } from 'react-redux';

const ResumeLayout = ({
  VerticalResumeTab,
  HorizontalResumeTab,
  ResumeForm,
  NextStep,
}) => {
  const [isNextStepOpen, setIsNextStepOpen] = useState(false);

  return (
    <Container className="w-full md:mt-0">
      <div className="h-full">
        {/* Mobile Navigation */}
        <div className="lg:hidden sticky top-0 z-10 bg-white">
          {HorizontalResumeTab}
        </div>

        <div className="grid">
          {/* Left Sidebar */}
          <div className="hidden lg:block sidebar left-sidebar brand-scrollbar">
            {VerticalResumeTab}
          </div>

          {/* Main Content - Resume Form */}
          <div className="flex flex-col h-full overflow-hidden main-content">
            <ProjectTitle className="flex-shrink-0" />
            <div className="flex-1 overflow-y-auto brand-scrollbar">
              {ResumeForm}
            </div>
          </div>

          {/* Right Sidebar */}
          <div className="hidden lg:block sidebar brand-scrollbar">
            {NextStep}
          </div>
        </div>

        {/* Mobile Next Step - Floating Button */}
        <button
          onClick={() => setIsNextStepOpen(true)}
          className="lg:hidden fixed bottom-4 right-4 z-20 bg-brand-cyan-dark text-white rounded-full p-4 shadow-lg"
        >
          <span className="sr-only">Open Insights</span>
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
        </button>

        {/* Mobile Next Step - Slide Up Panel */}
        {isNextStepOpen && (
          <div className="lg:hidden fixed inset-0 z-30 flex flex-col">
            <div
              className="absolute inset-0 bg-black/50"
              onClick={() => setIsNextStepOpen(false)}
            />
            <div className="relative mt-auto bg-white rounded-t-2xl max-h-[90vh] flex flex-col">
              {/* Header */}
              <div className="sticky top-0 bg-white p-4 border-b flex items-center justify-between">
                <div className="flex-1">
                  <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto" />
                </div>
                <button
                  onClick={() => setIsNextStepOpen(false)}
                  className="p-2"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto p-4 brand-scrollbar">
                <div className="pb-safe">{NextStep}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  overflow: hidden;

  .grid {
    height: calc(100vh - 1rem);

    @media (min-width: 1024px) {
      display: grid;
      gap: 1rem;
      padding: 1rem;
    }

    /* Large screens */
    @media (min-width: 1440px) {
      grid-template-columns: minmax(250px, 2fr) minmax(600px, 5fr) minmax(
          250px,
          3fr
        );
      gap: 1.5rem;
    }

    /* Medium screens */
    @media (min-width: 1280px) and (max-width: 1439px) {
      grid-template-columns: minmax(220px, 1.5fr) minmax(600px, 4fr) minmax(
          220px,
          2fr
        );
      gap: 1.25rem;
    }

    /* Smaller desktop screens */
    @media (min-width: 1024px) and (max-width: 1279px) {
      grid-template-columns: minmax(200px, 1fr) minmax(600px, 3fr) minmax(
          200px,
          1fr
        );
      gap: 1rem;
    }

    /* Hide left sidebar below 1350px */
    @media (min-width: 1024px) and (max-width: 1350px) {
      grid-template-columns: minmax(600px, 1fr) minmax(250px, 350px);
      gap: 1rem;
    }
  }

  .sidebar {
    @media (min-width: 1024px) {
      min-width: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }

    /* Add padding and scrollbar styling */
    padding: 0.5rem;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
  }

  .left-sidebar {
    @media (min-width: 1024px) and (max-width: 1350px) {
      display: none;
    }
  }

  .main-content {
    @media (min-width: 1024px) {
      width: 100%;
      min-width: 600px;
      max-width: 900px;
      margin: 0 auto;
    }
  }
`;

export default ResumeLayout;
