import { useState, useEffect } from 'react';
import { baseUrl } from '../../store/apis/config';
import { useSelector } from 'react-redux';

const usePortalSession = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const [portalURL, setPortalURL] = useState('');

  useEffect(() => {
    if (portalURL) return;

    const createPortalSesion = async (accessToken) => {
      fetch(`${baseUrl}/create-portal-session`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => setPortalURL(data))
        .catch((error) =>
          console.error('Error creating portal session:', error)
        );
    };

    const handleSubscription = async () => {
      try {
        await createPortalSesion(accessToken);
      } catch (error) {
        console.error('Error handling subscription:', error);
      }
    };

    handleSubscription();
  }, [portalURL, accessToken, baseUrl]);

  return portalURL;
};

export default usePortalSession;
