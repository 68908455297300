import React from "react";
import Author from "./Author";

const Testimonial = ({ testimonial }) => {
  const { name, image, role, quote } = testimonial;
  return (
    <div className="flex space-x-2">
      <img src="images/icons/quote.svg" alt="quote" className="self-start" />

      <div className="flex flex-col justify-start items-start space-y-6 italic mb-9">
        <p className="text-base md:text-lg">{quote}</p>
        <Author name={name} role={role} image={image} />
      </div>
    </div>
  );
};

export default Testimonial;
