import { CreateOrUpdateResume } from '../../components/Dashboard';
import { useSelector } from 'react-redux';

const ResumePage = () => {
  const { resumeObj } = useSelector((state) => state.project);

  return <CreateOrUpdateResume resume={resumeObj} />;
};

export default ResumePage;
