import React from 'react';
import { useSelector } from 'react-redux';

import DashboardHeader from '../common/DashboardHeader';
import FadeIn from 'react-fade-in/lib/FadeIn';

const DashboardTitle = ({ item, itemType = 'buttons' }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <FadeIn>
      <div className="flex flex-col-reverse md:flex-col">
        <div className="profile-header-shadow w-full">
          <div className="flex flex-col space-y-4 xl:flex-row xl:space-y-0  items-center justify-between profile-header-shadow py-5 md:py-10 xl:px-[300px]">
            <div className="px-3 xl:px-0 text-[32px] self-start md:text-[40px] text-black dark:text-white min-w-fit ">
              Hello {user?.given_name}!
            </div>
            {itemType === 'buttons' ? (
              <div className="hidden md:flex justify-end items-center w-full md:px-3 xl:px-0">
                {item}
              </div>
            ) : (
              <div className="w-full  px-3 xl:px-0 flex md:justify-end lg:justify-center items-center ">
                <div className="w-full lg:w-[600px] flex items-center justify-center">
                  {' '}
                  {item}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

export default DashboardTitle;
