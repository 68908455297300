import React, { useState } from "react";
import { JobDescriptionForm, UploadResume } from "../common/Forms";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";




const Quickstart = () => {
  const { resume, job } = useSelector((state) => state.project);

  // pointer to upload resume form
  const step1 = (
    <div className="flex flex-row-reverse relative my-6">
      <span className="text-2xl text-brand-blue joker">
        Give it a try yourself!
      </span>
     
      <img
        src="images/icons/arrow-left.png"
        alt="arrow left"
        className="absolute top-7 right-36"
      />
    </div>
  );

  // pointer to job description form
  const step2 = (
    <div className="flex relative my-6">
      <span className="text-2xl text-brand-pink joker ">
        Then do this next
      </span>
      <img
        src="images/icons/arrow-right.png"
        alt="arrow right"
        className="absolute top-7 left-28"
      />
    </div>
  );

  return (
    <div className="flex flex-col justify-center items-center">
      <div>{(!resume && !job) || !resume ? step1 : step2}</div>
      <div className="flex space-x-4 mt-8 mb-0 2xl:mb-20">
        <UploadResume />
        <JobDescriptionForm />
      </div>
    </div>
  );
};

export default Quickstart;
